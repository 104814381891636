import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// generalMission
const generalFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/general`, {
    params: params,
    headers: authHeader(),
  });
};

const generalFindone = (id) => {
  return http.get(SERVICE_API_URL + `/mission/general/${id}`, {
    headers: authHeader(),
  });
};

const generalAdd = (params) => {
  return http.post(SERVICE_API_URL + `/mission/general`, params, {
    headers: authHeader(),
  });
};

const generalModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/mission/general/${id}`, params, {
    headers: authHeader(),
  });
};

const generalRemove = (id) => {
  return http.put(SERVICE_API_URL + `/mission/general/${id}`, {
    headers: authHeader(),
  });
};

// rewardMission
const rewardFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/reward`, {
    params: params,
    headers: authHeader(),
  });
};

const rewardFindone = (id) => {
  return http.get(SERVICE_API_URL + `/mission/reward/${id}`, {
    headers: authHeader(),
  });
};

const rewardAdd = (params) => {
  return http.post(SERVICE_API_URL + `/mission/reward`, params, {
    headers: authHeader(),
  });
};

const rewardModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/mission/reward/${id}`, params, {
    headers: authHeader(),
  });
};

const rewardRemove = (id) => {
  return http.put(SERVICE_API_URL + `/mission/reward/${id}`, {
    headers: authHeader(),
  });
};

// missionReview
const missionReviewFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/review`, {
    params: params,
    headers: authHeader(),
  });
};

// missionCompleteGeneral
const missionCompleteGeneralFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/complete/general`, {
    params: params,
    headers: authHeader(),
  });
};

const missionCompleteGeneralFindone = (id) => {
  return http.get(SERVICE_API_URL + `/mission/complete/general/${id}`, {
    headers: authHeader(),
  });
};

const missionCompleteGeneralAdd = (params) => {
  return http.post(SERVICE_API_URL + `/mission/complete/general`, params, {
    headers: authHeader(),
  });
};

const missionCompleteGeneralModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/mission/complege/general/${id}`, params, {
    headers: authHeader(),
  });
};

const missionCompleteGeneralRemove = (id) => {
  return http.put(SERVICE_API_URL + `/mission/complete/general/${id}`, {
    headers: authHeader(),
  });
};

// missionCompleteReward
const missionCompleteRewardFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/complete/reward`, {
    params: params,
    headers: authHeader(),
  });
};

const missionCompleteRewardFindone = (id) => {
  return http.get(SERVICE_API_URL + `/mission/complete/reward/${id}`, {
    headers: authHeader(),
  });
};

const missionCompleteRewardAdd = (params) => {
  return http.post(SERVICE_API_URL + `/mission/complete/reward`, params, {
    headers: authHeader(),
  });
};

const missionCompleteRewardModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/mission/complege/reward/${id}`, params, {
    headers: authHeader(),
  });
};

const missionCompleteRewardRemove = (id) => {
  return http.put(SERVICE_API_URL + `/mission/complete/reward/${id}`, {
    headers: authHeader(),
  });
};

const missionFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission`, {
    params: params,
    headers: authHeader(),
  });
};

// missionFineone
const missionFindone = (id) => {
  return http.get(SERVICE_API_URL + `/mission/${id}`, {
    headers: authHeader(),
  });
};

// missionAdd
const missionAdd = (params) => {
  return http.post(SERVICE_API_URL + `/mission`, params, {
    headers: authHeader(),
  });
};

// missionModify
const missionModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/mission/${id}`, params, {
    headers: authHeader(),
  });
};

// missionRemove
const missionRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/mission/${id}`, {
    headers: authHeader(),
  });
};

// missionCompleteGroup
const missionCompleteGroupGeneralFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/complete/group/general`, {
    params: params,
    headers: authHeader(),
  });
};

const missionCompleteGroupRewardFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/mission/complete/group/reward`, {
    params: params,
    headers: authHeader(),
  });
};

// 통계 statistics
const generalCompleteDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-complete-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const rewardCompleteDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-reward-complete-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionReviewDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-review-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionRewardReviewDaily = (params) => {
  return http.get(SERVICE_API_URL + `/mission/statistics-reward-review-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const missionGenderDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-complete-gender-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionCountryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-complete-country-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionLangDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-complete-lang-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionAgeDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-complete-age-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionCategoryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-complete-category-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionSearchDaily = (params) => {
  return http.get(SERVICE_API_URL + `/mission/statistics-search-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const missionReviewRateDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-review-rate-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const missionReviewWriteRateDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/mission/statistics-general-review-write-rate-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

// reset user phone number 인증번호 제거
const resetUserPhone = (id, params) => {
  return http.patch(SERVICE_API_URL + `/mission/complete/${id}`, params, {
    headers: authHeader(),
  });
};

export default {
  generalFindAll,
  generalFindone,
  generalAdd,
  generalModify,
  generalRemove,
  rewardFindAll,
  rewardFindone,
  rewardAdd,
  rewardModify,
  rewardRemove,
  missionReviewFindAll,
  missionCompleteGeneralFindAll,
  missionCompleteGeneralFindone,
  missionCompleteGeneralAdd,
  missionCompleteGeneralModify,
  missionCompleteGeneralRemove,
  missionCompleteRewardFindAll,
  missionCompleteRewardFindone,
  missionCompleteRewardAdd,
  missionCompleteRewardModify,
  missionCompleteRewardRemove,
  missionFindone,
  missionAdd,
  missionModify,
  missionRemove,
  missionFindAll,
  missionCompleteGroupGeneralFindAll,
  missionCompleteGroupRewardFindAll,
  generalCompleteDaily,
  rewardCompleteDaily,
  missionReviewDaily,
  missionRewardReviewDaily,
  missionGenderDaily,
  missionCountryDaily,
  missionLangDaily,
  missionAgeDaily,
  missionCategoryDaily,
  missionSearchDaily,
  missionReviewRateDaily,
  missionReviewWriteRateDaily,
  resetUserPhone,
};
