<!-- table_area -->
<template>
  <table width="100%">
    <colgroup>
      <col v-if="isCheckbox" style="width: 100px" />
      <col v-if="useNo" style="width: 70px" />
      <col
        v-for="(item, index) in fields"
        :key="`col_${index}`"
        :style="{ width: item.width ? item.width : '' }"
      />
      <col v-if="isEdit || isDelete" style="width: 80px" />
      <col v-if="isStamp" style="width: 100px" />
    </colgroup>
    <thead>
      <tr>
        <th v-if="isCheckbox">{{ checkText }}</th>
        <th v-if="useNo">번호</th>
        <th
          class="table_title"
          v-for="(item, index) in fields"
          :key="`th_${index}`"
        >
          <span
            v-if="item.useSort"
            @click.prevent.stop="
              sortClick($event, item, fields, index, clickedNum)
            "
          >
            {{ item.label }}
            <!-- XXX sort 개선필요-->
            <i>
              <font-awesome-icon
                v-if="
                  clickedNum == 0 ||
                  item.clickedNum == 0 ||
                  clickedNum != item.clickedNum
                "
                :icon="['fas', 'sort']"
                >-</font-awesome-icon
              >
              <font-awesome-icon
                v-if="clickedNum == 1 && item.clickedNum == 1"
                :icon="['fas', 'sort-down']"
              />
              <font-awesome-icon
                v-if="clickedNum == 2 && item.clickedNum == 2"
                :icon="['fas', 'sort-up']"
              />
            </i>
          </span>
          <span v-else> {{ item.label }}</span>
        </th>
        <th v-if="isEdit">
          {{ editText }}<span v-if="getUser.role != 'ADMIN_VIEWER'">/삭제</span>
        </th>
        <th v-if="isDelete">삭제</th>
        <th v-if="isStamp">스탬프 획득처리</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="loading && items.length == 0">
        <td colspan="100">
          {{ loadingMessage }}
        </td>
      </tr>
      <tr v-else-if="!loading && items.length == 0">
        <td colspan="100">{{ emptyMessage }}</td>
      </tr>
      <tr v-else v-for="(item, index) in items" :key="index">
        <template>
          <td v-if="isCheckbox">
            <input
              v-if="selectType == 'checkbox'"
              type="checkbox"
              :value="item.id"
              :id="item.id"
              v-model="checked"
            />
            <input
              v-else
              type="radio"
              :value="item.id"
              :id="item.id"
              v-model="checked"
            />
          </td>
          <td v-if="useNo">
            {{ $n(totalItems - pageSize * (currentPage - 1) - index) }}
          </td>
          <td v-for="(field, index) in fields" :key="`td_${index}`">
            <template v-if="hasValue(item, field)">
              <component
                v-if="field.tag"
                :is="'tag-' + field.tag"
                :item="item"
                :field="field"
                :id="item.id"
                @click="handleClick"
                :useClick="field.useClick"
                :useSort="field.useSort"
                :oneText="field.oneText"
                :data="itemValue(item, field)"
              ></component>

              <!-- /// -->
              <template v-else-if="field.useSlot">
                <slot :name="field.key" :item="item" />
              </template>
              <!-- /// -->

              <template v-else>
                <a
                  href="#"
                  v-if="field.useClick"
                  @click.prevent.stop="handleClick($event, item, field)"
                >
                  {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                </a>
                <a
                  href="#"
                  v-else-if="field.downloadClick"
                  @click.prevent.stop="downloadClick($event, item, field)"
                  download
                >
                  {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                </a>
                <div v-else-if="field.oneText" class="oneText">
                  {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                </div>
                <template v-else>
                  {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                </template>
              </template>
            </template>
            <template v-else> - </template>
          </td>
          <template v-if="isEdit">
            <td>
              <button type="button" @click="editClick($event, item)">
                <i><font-awesome-icon :icon="['fas', 'edit']" /></i>
              </button>
              <button
                v-if="getUser.role != 'ADMIN_VIEWER'"
                type="button"
                @click="deleteClick($event, item)"
              >
                <i><font-awesome-icon :icon="['fas', 'trash-alt']" /></i>
              </button>
            </td>
          </template>
          <template v-if="isDelete">
            <td>
              <button type="button" @click="deleteClick($event, item)">
                <i><font-awesome-icon :icon="['fas', 'trash-alt']" /></i>
              </button>
            </td>
          </template>
          <template v-if="isStamp">
            <td>
              <base-button type="upload" class="height_40">
                획득처리
              </base-button>
            </td>
          </template>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import img from "./Img";
import { mapGetters } from "vuex";

export default {
  name: "BaseTable",
  components: {
    "tag-img": img,
  },
  props: {
    useNo: {
      type: Boolean,
      default: false,
      description: "Table loading",
    },
    totalItems: {
      type: Number,
      default: 0,
      description: "",
    },
    currentPage: {
      type: Number,
      default: 0,
      description: "",
    },
    pageSize: {
      type: Number,
      default: 0,
      description: "",
    },
    loadingMessage: {
      type: String,
      default: "조회중..",
      description: "",
    },
    emptyMessage: {
      type: String,
      default: "검색 결과가 없습니다.",
      description: "",
    },
    fields: {
      type: Array,
      default: () => [],
      description: "Table Fields",
    },
    loading: {
      type: Boolean,
      default: true,
      description: "Table loading",
    },
    items: {
      type: Array,
      default: () => [],
      description: "Table Items",
    },
    // isSort: {
    //   type: Boolean,
    //   default: false,
    //   description: "Show sort icon",
    // },
    isEdit: {
      type: Boolean,
      default: true,
      description: "Show edit buttons",
    },
    isDelete: {
      type: Boolean,
      default: false,
      description: "Show only delete button",
    },
    isStamp: {
      type: Boolean,
      default: false,
      description: "show gain stamp button",
    },
    isCheckbox: {
      type: Boolean,
      default: false,
      description: "Show checkbox",
    },
    modelValue: {
      type: String,
      default: "",
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    defaultChecked: {
      type: [Array, Number, String],
      default: () => [],
      description: "Table checked",
    },
    editText: {
      type: String,
      default: "수정",
    },
    checkText: {
      type: String,
      default: "선택",
    },
    selectType: {
      type: String,
      default: "checkbox",
    },
    clickedNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checked: [],
      radioaa: "checked",
    };
  },
  created() {
    if (this.selectType == "checkbox") {
      this.checked = this.defaultChecked;
    } else if (this.selectType == "radio") {
      this.checked = this.defaultChecked;
    } else {
      this.checked = this.defaultChecked.toString();
    }
  },
  mounted() {
    /**
     * mounted
     */
  },
  watch: {
    checked(value) {
      this.$emit("onChecked", value);
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    handleClick(event, item, field) {
      this.$emit("click", {
        event: event,
        item: item,
        field: field,
      });
    },
    sortClick(event, item, field, index, clickedNum) {
      this.$emit("sort", {
        event: event,
        item: item,
        field: field,
        index: index,
        clickedNum: clickedNum,
      });
    },
    editClick(event, item) {
      this.$emit("edit", {
        event: event,
        item: item,
      });
    },
    deleteClick(event, item) {
      this.$emit("delete", {
        event: event,
        item: item,
      });
    },
    hasValue(item, field) {
      /**
       * XXX
       * 로직 개선 필요.
       */
      if (1 < field.key.split(".").length) {
        if (
          Object.prototype.hasOwnProperty.call(item, field.key.split(".")[0])
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              item[field.key.split(".")[0]],
              field.key.split(".")[1]
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(item, field.key)) {
          // console.log(field.key);
          return true;
        } else {
          return false;
        }
      }
    },
    itemValue(item, field) {
      /**
       * XXX
       * 로직 개선 필요.
       */
      let value = "";
      if (1 < field.key.split(".").length) {
        value = item[field.key.split(".")[0]][field.key.split(".")[1]];
      } else {
        value = item[field.key];
      }
      if (field.formatter) {
        if (field.formatter.name == "date") {
          try {
            value = this.$d(new Date(value).getTime(), field.formatter.type);
          } catch (e) {
            console.log(e);
            value = "";
          }
        } else if (field.formatter.name == "number") {
          value = this.$n(value);
        } else if (field.formatter.name == "percent") {
          if (value) {
            console.log(value);
            value = value.toFixed(2);
          }
        }
      }
      return value;
    },
  },
};
</script>
