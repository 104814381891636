<template>
  <div class="login_body">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {
      logo: {
        src: "logo.png",
        text: "스탬프 팝",
      },
      menus: [
        {
          label: "대시보드",
          imgIcon: "menu_01.png",
          router: {
            name: "Dashboard",
          },
        },
        {
          label: "통계 관리",
          imgIcon: "menu_02.png",
          show: false,
          child: [
            {
              label: "통계 관리",
              router: {
                name: "Statistics",
              },
            },
            {
              label: "전체 획득관리",
              router: {
                name: "StatsAllGain",
              },
            },
            {
              label: "미션 완성관리",
              router: {
                name: "StatsMissionComplete",
              },
            },
            {
              label: "지자체 관리",
              router: {
                name: "StatsLocalGoverment",
              },
            },
            {
              label: "스탬프 별 통계관리",
              router: {
                name: "StatsStamp",
              },
            },
          ],
        },
        {
          label: "스탬프 관리",
          imgIcon: "menu_03.png",
          show: false,
          child: [
            {
              label: "스탬프 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "미션 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "미션 상단 이벤트 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "추천 스탬프 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "축제 관리",
              router: {
                name: "router-name",
              },
            },
          ],
        },
        {
          label: "커뮤니티 관리",
          imgIcon: "menu_04.png",
          show: false,
          child: [
            {
              label: "커뮤니티 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "메인 배너 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "광고관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "추천 Tip 관리",
              router: {
                name: "router-name",
              },
            },
            {
              label: "문제신고 관리",
              router: {
                name: "router-name",
              },
            },
          ],
        },
        {
          label: "회원 관리",
          imgIcon: "menu_05.png",
          show: false,
          router: {
            name: "router-name",
          },
        },
        {
          label: "입점&제휴문의 관리",
          imgIcon: "menu_07.png",
          router: {
            name: "router-name",
          },
        },
        {
          label: "문제신고 관리",
          imgIcon: "menu_06.png",
          router: {
            name: "router-name",
          },
        },
        {
          label: "관리자 관리",
          imgIcon: "menu_08.png",
          router: {
            name: "router-name",
          },
        },
      ],
    };
  },
};
</script>

<style></style>
