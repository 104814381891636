import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const userFindone = (id) => {
  return http.get(SERVICE_API_URL + `/user/${id}`, {
    headers: authHeader(),
  });
};
// generalUser
const generalFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/user/general`, {
    params: params,
    headers: authHeader(),
  });
};

const generalFindone = (id) => {
  return http.get(SERVICE_API_URL + `/user/general/${id}`, {
    headers: authHeader(),
  });
};

const generalAdd = (params) => {
  return http.post(SERVICE_API_URL + `/user/general`, params, {
    headers: authHeader(),
  });
};

const generalModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/user/general/${id}`, params, {
    headers: authHeader(),
  });
};

const generalRemove = (id) => {
  return http.put(SERVICE_API_URL + `/user/general/${id}`, {
    headers: authHeader(),
  });
};

// clientUser
const clientFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/user/client`, {
    params: params,
    headers: authHeader(),
  });
};

const clientFindone = (id) => {
  return http.get(SERVICE_API_URL + `/user/client/${id}`, {
    headers: authHeader(),
  });
};

const clientAdd = (params) => {
  return http.post(SERVICE_API_URL + `/user/client`, params, {
    headers: authHeader(),
  });
};

const clientModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/user/client/${id}`, params, {
    headers: authHeader(),
  });
};

const clientRemove = (id) => {
  return http.put(SERVICE_API_URL + `/user/client/${id}`, {
    headers: authHeader(),
  });
};

// admin
const adminFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/user/admin`, {
    params: params,
    headers: authHeader(),
  });
};

const adminFindone = (id) => {
  return http.get(SERVICE_API_URL + `/user/admin/${id}`, {
    headers: authHeader(),
  });
};

const adminAdd = (params) => {
  return http.post(SERVICE_API_URL + `/user/admin`, params, {
    headers: authHeader(),
  });
};

const adminModify = (id, params) => {
  return http.patch(SERVICE_API_URL + `/user/admin/${id}`, params, {
    headers: authHeader(),
  });
};

const adminRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/user/admin/${id}`, {
    headers: authHeader(),
  });
};

const generalUnregisterFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/user/general-unregister`, {
    params: params,
    headers: authHeader(),
  });
};

const generalRegisterFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/user/general-register`, {
    params: params,
    headers: authHeader(),
  });
};

const generalDormantFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/user/general-dormant`, {
    params: params,
    headers: authHeader(),
  });
};

// statistics 통계
const userRegisterDaily = (params) => {
  return http.get(SERVICE_API_URL + `/user/statistics-general-register-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const clientRegisterDaily = (params) => {
  return http.get(SERVICE_API_URL + `/user/statistics-client-register-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const registerGenderDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/user/statistics-general-register-gender-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const registerCountryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/user/statistics-general-register-country-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const registerLangDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/user/statistics-general-register-lang-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const registerAgeDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/user/statistics-general-register-age-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

export default {
  generalFindAll,
  generalFindone,
  generalAdd,
  generalModify,
  generalRemove,
  clientFindAll,
  clientFindone,
  clientAdd,
  clientModify,
  clientRemove,
  adminFindAll,
  adminFindone,
  adminAdd,
  adminModify,
  adminRemove,
  userFindone,
  generalUnregisterFindAll,
  generalRegisterFindAll,
  generalDormantFindAll,
  userRegisterDaily,
  clientRegisterDaily,
  registerGenderDaily,
  registerCountryDaily,
  registerLangDaily,
  registerAgeDaily,
};
