import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const advertisementFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/advertisement`, {
    params: params,
    headers: authHeader(),
  });
};

const advertisementFindone = (id) => {
  return http.get(SERVICE_API_URL + `/advertisement/${id}`, {
    headers: authHeader(),
  });
};

const advertisementAdd = (params) => {
  return http.post(SERVICE_API_URL + `/advertisement`, params, {
    headers: authHeader(),
  });
};

const advertisementModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/advertisement/${id}`, params, {
    headers: authHeader(),
  });
};

const advertisementRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/advertisement/${id}`, {
    headers: authHeader(),
  });
};

export default {
  advertisementFindAll,
  advertisementFindone,
  advertisementAdd,
  advertisementModify,
  advertisementRemove,
};
