import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const reportFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/report`, {
    params: params,
    headers: authHeader(),
  });
};

const reportFindone = (id) => {
  return http.get(SERVICE_API_URL + `/report/${id}`, {
    headers: authHeader(),
  });
};

const reportModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/report/${id}`, params, {
    headers: authHeader(),
  });
};

const reportRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/report/${id}`, {
    headers: authHeader(),
  });
};

export default {
  reportFindAll,
  reportFindone,
  reportModify,
  reportRemove,
};
