import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// generalStamp
const generalFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/general`, {
    params: params,
    headers: authHeader(),
  });
};

const generalFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/general/${id}`, {
    headers: authHeader(),
  });
};

const generalAdd = (params) => {
  return http.post(SERVICE_API_URL + `/stamp/general`, params, {
    headers: authHeader(),
  });
};

const generalModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/stamp/general/${id}`, params, {
    headers: authHeader(),
  });
};

const generalRemove = (id) => {
  return http.put(SERVICE_API_URL + `/stamp/general/${id}`, {
    headers: authHeader(),
  });
};

// stampReview
const stampReviewFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/review`, {
    params: params,
    headers: authHeader(),
  });
};

// stampRequest
const stampRequestFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/request/stamp`, {
    params: params,
    headers: authHeader(),
  });
};

const stampRequestFindOne = (id) => {
  return http.get(SERVICE_API_URL + `/request/stamp/${id}`, {
    headers: authHeader(),
  });
};

const stampRequestModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/request/stamp/${id}`, params, {
    headers: authHeader(),
  });
};

const stampRequestRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/request/stamp/${id}`, {
    headers: authHeader(),
  });
};

// rewardStamp
const rewardFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/reward`, {
    params: params,
    headers: authHeader(),
  });
};

const rewardFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/reward/${id}`, {
    headers: authHeader(),
  });
};

const rewardAdd = (params) => {
  return http.post(SERVICE_API_URL + `/stamp/reward`, params, {
    headers: authHeader(),
  });
};

const rewardModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/stamp/reward/${id}`, params, {
    headers: authHeader(),
  });
};

const rewardRemove = (id) => {
  return http.put(SERVICE_API_URL + `/stamp/reward/${id}`, {
    headers: authHeader(),
  });
};

// acquisitionGeneralStamp
const acquisitionGeneralFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/general`, {
    params: params,
    headers: authHeader(),
  });
};

const acquisitionGeneralFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/general/${id}`, {
    headers: authHeader(),
  });
};

const acquisitionGeneralAdd = (params) => {
  return http.post(SERVICE_API_URL + `/stamp/acquisition/general`, params, {
    headers: authHeader(),
  });
};

const acquisitionGeneralModify = (id, params) => {
  return http.put(
    SERVICE_API_URL + `/stamp/acquisition/general/${id}`,
    params,
    {
      headers: authHeader(),
    }
  );
};

const acquisitionGeneralRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/stamp/acquisition/general/${id}`, {
    headers: authHeader(),
  });
};

// acquisitionRewardStamp
const acquisitionRewardFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/reward`, {
    params: params,
    headers: authHeader(),
  });
};

const acquisitionRewardFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/reward/${id}`, {
    headers: authHeader(),
  });
};

const acquisitionRewardAdd = (params) => {
  return http.post(SERVICE_API_URL + `/stamp/acquisition/reward`, params, {
    headers: authHeader(),
  });
};

const acquisitionRewardModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/stamp/acquisition/reward/${id}`, params, {
    headers: authHeader(),
  });
};

const acquisitionRewardRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/stamp/acquisition/reward/${id}`, {
    headers: authHeader(),
  });
};

// acquisitionGroupGeneralStamp
const acquisitionGroupGeneralFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/group/general`, {
    params: params,
    headers: authHeader(),
  });
};

const acquisitionGroupGeneralFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/group/general/${id}`, {
    headers: authHeader(),
  });
};

const acquisitionGroupGeneralAdd = (params) => {
  return http.post(
    SERVICE_API_URL + `/stamp/acquisition/group/general`,
    params,
    {
      headers: authHeader(),
    }
  );
};

const acquisitionGroupGeneralModify = (id, params) => {
  return http.put(
    SERVICE_API_URL + `/stamp/acquisition/group/general/${id}`,
    params,
    {
      headers: authHeader(),
    }
  );
};

const acquisitionGroupGeneralRemove = (id) => {
  return http.put(SERVICE_API_URL + `/stamp/acquisition/group/general/${id}`, {
    headers: authHeader(),
  });
};

const stampGroupFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/group/${id}`, {
    headers: authHeader(),
  });
};

const oldStampGroupFindone = (id) => {
  return http.get(SERVICE_API_URL + `/old/stamp/group/${id}`, {
    headers: authHeader(),
  });
};

const stampMissionFindone = (id, params) => {
  return http.get(SERVICE_API_URL + `/stamp/mission/${id}`, {
    params: params,
    headers: authHeader(),
  });
};

// acquisitionGroupRewardStamp
const acquisitionGroupRewardFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/group/reward`, {
    params: params,
    headers: authHeader(),
  });
};

const acquisitionGroupRewardFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/group/reward/${id}`, {
    headers: authHeader(),
  });
};

const acquisitionGroupRewardAdd = (params) => {
  return http.post(
    SERVICE_API_URL + `/stamp/acquisition/group/reward`,
    params,
    {
      headers: authHeader(),
    }
  );
};

const acquisitionGroupRewardModify = (id, params) => {
  return http.put(
    SERVICE_API_URL + `/stamp/acquisition/group/reward/${id}`,
    params,
    {
      headers: authHeader(),
    }
  );
};

const acquisitionGroupRewardRemove = (id) => {
  return http.put(SERVICE_API_URL + `/stamp/acquisition/group/reward/${id}`, {
    headers: authHeader(),
  });
};
// acquisitionClientStamp
const acquisitionClientFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/client`, {
    params: params,
    headers: authHeader(),
  });
};

const acquisitionClientFindone = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/client/${id}`, {
    headers: authHeader(),
  });
};

const acquisitionClientAdd = (params) => {
  return http.post(SERVICE_API_URL + `/stamp/acquisition/client`, params, {
    headers: authHeader(),
  });
};

const acquisitionClientModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/stamp/acquisition/client/${id}`, params, {
    headers: authHeader(),
  });
};

const acquisitionClientRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/stamp/acquisition/client/${id}`, {
    headers: authHeader(),
  });
};

// stampAdd
const stampAdd = (params) => {
  return http.post(SERVICE_API_URL + `/stamp`, params, {
    headers: authHeader(),
  });
};

// stampModify
const stampModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/stamp/${id}`, params, {
    headers: authHeader(),
  });
};

// stampRemove
const stampRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/stamp/${id}`, {
    headers: authHeader(),
  });
};

// stampFindOne
const stampFindOne = (id) => {
  return http.get(SERVICE_API_URL + `/stamp/${id}`, {
    headers: authHeader(),
  });
};

// stampFind
const stampFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/stamp`, {
    params: params,
    headers: authHeader(),
  });
};

// 통계 statistics
const generalAquisitionDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-acquisition-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const clientAquisitionDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-client-acquisition-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const badgeAquisitionDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-badge-acquisition-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const generalReviewDaily = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/statistics-general-review-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const clientReviewDaily = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/statistics-client-review-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const badgeReviewDaily = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/statistics-badge-review-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const acquisitionGenderDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-acquisition-gender-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const acquisitionCountryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-acquisition-country-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const acquisitionLangDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-acquisition-lang-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const acquisitionAgeDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-acquisition-age-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const acquisitionCategoryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-acquisition-category-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const stampSearchDaily = (params) => {
  return http.get(SERVICE_API_URL + `/stamp/statistics-search-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const reviewRateDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-review-rate-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};
const reviewWriteRateDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/stamp/statistics-general-review-write-rate-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};
export default {
  generalFindAll,
  generalFindone,
  generalAdd,
  generalModify,
  generalRemove,
  stampReviewFindAll,
  stampRequestFindAll,
  stampRequestFindOne,
  stampRequestModify,
  stampRequestRemove,
  rewardFindAll,
  rewardFindone,
  rewardAdd,
  rewardModify,
  rewardRemove,
  stampGroupFindone,
  oldStampGroupFindone,
  stampMissionFindone,
  acquisitionGeneralFindAll,
  acquisitionGeneralFindone,
  acquisitionGeneralAdd,
  acquisitionGeneralModify,
  acquisitionGeneralRemove,
  acquisitionRewardFindAll,
  acquisitionRewardFindone,
  acquisitionRewardAdd,
  acquisitionRewardModify,
  acquisitionRewardRemove,
  acquisitionGroupGeneralFindAll,
  acquisitionGroupGeneralFindone,
  acquisitionGroupGeneralAdd,
  acquisitionGroupGeneralModify,
  acquisitionGroupGeneralRemove,
  acquisitionGroupRewardFindAll,
  acquisitionGroupRewardFindone,
  acquisitionGroupRewardAdd,
  acquisitionGroupRewardModify,
  acquisitionGroupRewardRemove,
  acquisitionClientFindAll,
  acquisitionClientFindone,
  acquisitionClientAdd,
  acquisitionClientModify,
  acquisitionClientRemove,
  stampAdd,
  stampModify,
  stampRemove,
  stampFindAll,
  stampFindOne,
  generalAquisitionDaily,
  clientAquisitionDaily,
  badgeAquisitionDaily,
  generalReviewDaily,
  clientReviewDaily,
  badgeReviewDaily,
  acquisitionGenderDaily,
  acquisitionCountryDaily,
  acquisitionLangDaily,
  acquisitionAgeDaily,
  acquisitionCategoryDaily,
  stampSearchDaily,
  reviewRateDaily,
  reviewWriteRateDaily,
};
