// export const API_URL =
//   process.env.NODE_ENV == "development"
//     ? "https://api.stamppop.nextinnovation.kr"
//     : "https://api.stamppop.com";
export const API_URL = process.env.VUE_APP_BASE_URL;

export const SERVICE_API_URL = "/api/v3/admin";

export const VERSION = 1;
export const VERSION_CODE = "0.0.1";
