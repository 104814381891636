import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// banner 추천팁
const bannerFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/banner/content`, {
    params: params,
    headers: authHeader(),
  });
};

const bannerFindone = (id) => {
  return http.get(SERVICE_API_URL + `/banner/content/${id}`, {
    headers: authHeader(),
  });
};

const bannerAdd = (params) => {
  return http.post(SERVICE_API_URL + `/banner/content`, params, {
    headers: authHeader(),
  });
};

const bannerModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/banner/content/${id}`, params, {
    headers: authHeader(),
  });
};

const bannerRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/banner/content/${id}`, {
    headers: authHeader(),
  });
};
// oldBanner
const oldBannerFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/old/banner`, {
    params: params,
    headers: authHeader(),
  });
};

const oldBannerFindone = (id) => {
  return http.get(SERVICE_API_URL + `/old/banner/${id}`, {
    headers: authHeader(),
  });
};

const oldBannerAdd = (params) => {
  return http.post(SERVICE_API_URL + `/old/banner`, params, {
    headers: authHeader(),
  });
};

const oldBannerModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/old/banner/${id}`, params, {
    headers: authHeader(),
  });
};

const oldBannerRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/old/banner/${id}`, {
    headers: authHeader(),
  });
};
// event
const eventFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/event`, {
    params: params,
    headers: authHeader(),
  });
};

const eventFindone = (id) => {
  return http.get(SERVICE_API_URL + `/event/${id}`, {
    headers: authHeader(),
  });
};

const eventAdd = (params) => {
  return http.post(SERVICE_API_URL + `/event`, params, {
    headers: authHeader(),
  });
};

const eventModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/event/${id}`, params, {
    headers: authHeader(),
  });
};

const eventRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/event/${id}`, {
    headers: authHeader(),
  });
};

// banner-top
const bannerTopFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/banner/general`, {
    params: params,
    headers: authHeader(),
  });
};

const bannerTopFindone = (id) => {
  return http.get(SERVICE_API_URL + `/banner/general/${id}`, {
    headers: authHeader(),
  });
};

const bannerTopAdd = (params) => {
  return http.post(SERVICE_API_URL + `/banner/general`, params, {
    headers: authHeader(),
  });
};

const bannerTopModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/banner/general/${id}`, params, {
    headers: authHeader(),
  });
};

const bannerTopRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/banner/general/${id}`, {
    headers: authHeader(),
  });
};

// festival
const festivalFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/festival`, {
    params: params,
    headers: authHeader(),
  });
};

const festivalFindone = (id) => {
  return http.get(SERVICE_API_URL + `/festival/${id}`, {
    headers: authHeader(),
  });
};

const festivalAdd = (params) => {
  return http.post(SERVICE_API_URL + `/festival`, params, {
    headers: authHeader(),
  });
};

const festivalModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/festival/${id}`, params, {
    headers: authHeader(),
  });
};

const festivalRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/festival/${id}`, {
    headers: authHeader(),
  });
};

// popup
const popupFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/popup`, {
    params: params,
    headers: authHeader(),
  });
};

const popupFindone = (id) => {
  return http.get(SERVICE_API_URL + `/popup/${id}`, {
    headers: authHeader(),
  });
};

const popupAdd = (params) => {
  return http.post(SERVICE_API_URL + `/popup`, params, {
    headers: authHeader(),
  });
};

const popupModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/popup/${id}`, params, {
    headers: authHeader(),
  });
};

const popupRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/popup/${id}`, {
    headers: authHeader(),
  });
};

export default {
  bannerFindAll,
  bannerFindone,
  bannerAdd,
  bannerModify,
  bannerRemove,
  oldBannerFindAll,
  oldBannerFindone,
  oldBannerAdd,
  oldBannerModify,
  oldBannerRemove,
  eventFindAll,
  eventFindone,
  eventAdd,
  eventModify,
  eventRemove,
  bannerTopFindAll,
  bannerTopFindone,
  bannerTopAdd,
  bannerTopModify,
  bannerTopRemove,
  festivalFindAll,
  festivalFindone,
  festivalAdd,
  festivalModify,
  festivalRemove,
  popupFindAll,
  popupFindone,
  popupAdd,
  popupModify,
  popupRemove,
};
