<template>
  <div id="wrap">
    <div class="wrap_inner">
      <base-side-bar v-if="true" :logo="logo" :menus="menusByRole" />
      <div class="header">
        <h1 class="title">
          <span :class="{ single: subtitle.length == 0 }">{{ title }}</span>
          {{ subtitle }}
        </h1>
        <ul class="member">
          <li class="member_box">
            <a href="#none">{{ getUser.name }}({{ getUser.loginid }})님</a>
          </li>
          <li class="member_box">
            <a href="#none" @click.prevent.stop="logout">로그아웃</a>
          </li>
        </ul>
      </div>
      <main id="content">
        <router-view />
      </main>
      <vue-progress-bar></vue-progress-bar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BasicLayout",
  components: {},
  created() {
    this.breadcrumb(this.$route.meta.breadcrumb);
  },
  data() {
    return {
      title: "",
      subtitle: "",
      logo: {
        src: "logo.png",
        text: "스탬프 팝",
      },
      menus: [
        {
          label: "대시보드",
          role: ["ADMIN_MANAGER", "ADMIN_EDITOR", "ADMIN_ANALYST"],
          fontIcon: {
            style: "fas",
            icon: "columns",
          },
          router: {
            name: "Dashboard",
          },
        },
        {
          label: "통계 관리",
          role: ["ADMIN_MANAGER", "ADMIN_EDITOR", "ADMIN_ANALYST"],
          fontIcon: {
            style: "fas",
            icon: "chart-bar",
          },
          router: {
            name: "Statistics",
          },
        },
        {
          label: "회원",
          role: ["ADMIN_MANAGER", "ADMIN_EDITOR", "ADMIN_ANALYST"],
          fontIcon: {
            style: "fas",
            icon: "user",
          },
          show: false,
          child: [
            {
              label: "대시보드",
              router: {
                name: "UserDashboard",
              },
            },
            {
              label: "지자체 회원",
              router: {
                name: "UserUserClient",
              },
            },
            {
              label: "일반 회원",
              router: {
                name: "UserUserGeneral",
              },
            },
          ],
        },
        {
          label: "스탬프",
          role: [
            "ADMIN_MANAGER",
            "ADMIN_EDITOR",
            "ADMIN_ANALYST",
            "ADMIN_VIEWER",
          ],
          fontIcon: {
            style: "fas",
            icon: "map-marker",
          },
          show: false,
          child: [
            {
              label: "대시보드",
              router: {
                name: "StampDashboard",
              },
            },
            {
              label: "일반 스탬프",
              router: {
                name: "StampStampGeneral",
              },
            },
            {
              label: "배지 스탬프",
              router: {
                name: "StampStampReward",
              },
            },
            {
              label: "일반 스탬프 획득 현황",
              router: {
                name: "StampAcquisitionPresentGeneral",
              },
            },
            {
              label: "배지 스탬프 획득 현황",
              router: {
                name: "StampAcquisitionPresentReward",
              },
            },
            {
              label: "일반 스탬프 별 획득 현황",
              router: {
                name: "StampAcquisitionGroupPresentGeneral",
              },
            },
            {
              label: "배지 스탬프 별 획득 현황",
              router: {
                name: "StampAcquisitionGroupPresentReward",
              },
            },
            {
              label: "지자체 스탬프 별 획득 현황",
              router: {
                name: "StampAcquisitionClientPresent",
              },
            },
          ],
        },
        {
          label: "미션",
          role: [
            "ADMIN_MANAGER",
            "ADMIN_EDITOR",
            "ADMIN_ANALYST",
            "ADMIN_VIEWER",
          ],
          fontIcon: {
            style: "fas",
            icon: "scroll",
          },
          show: false,
          child: [
            {
              label: "대시보드",
              router: {
                name: "MissionDashboard",
              },
            },
            {
              label: "일반 미션",
              router: {
                name: "MissionMissionGeneral",
              },
            },
            {
              label: "배지 미션",
              router: {
                name: "MissionMissionReward",
              },
            },
            {
              label: "일반 미션 완료 현황",
              router: {
                name: "MissionMissionCompletePresentGeneral",
              },
            },
            {
              label: "배지 미션 완료 현황",
              router: {
                name: "MissionMissionCompletePresentReward",
              },
            },
            {
              label: "일반 미션별 완료 현황",
              router: {
                name: "MissionMissionCompleteGroupPresentGeneral",
              },
            },
            {
              label: "배지 미션별 완료 현황",
              router: {
                name: "MissionMissionCompleteGroupPresentReward",
              },
            },
          ],
        },
        {
          label: "커뮤니티",
          role: [
            "ADMIN_MANAGER",
            "ADMIN_EDITOR",
            "ADMIN_ANALYST",
            "ADMIN_VIEWER",
          ],
          fontIcon: {
            style: "fas",
            icon: "comment",
          },
          show: false,
          child: [
            // {
            //   label: "대시보드",
            //   router: {
            //     name: "CommunityDashboard",
            //   },
            // },
            {
              label: "일반 게시글",
              router: {
                name: "CommunityPostGeneral",
              },
            },
            // {
            //   label: "스탬프 로그 게시글",
            //   router: {
            //     name: "CommunityPostStampLog",
            //   },
            // },
            // {
            //   label: "댓글",
            //   router: {
            //     name: "CommunityComment",
            //   },
            // },
            // {
            //   label: "좋아요",
            //   router: {
            //     name: "CommunityLike",
            //   },
            // },
            // {
            //   label: "공유",
            //   router: {
            //     name: "CommunityShare",
            //   },
            // },
            /*
            {
              label: "문제신고",
              router: {
                name: "CommunityReport",
              },
            },
            */
          ],
        },
        {
          label: "콘텐츠",
          role: [
            "ADMIN_MANAGER",
            "ADMIN_EDITOR",
            "ADMIN_ANALYST",
            "ADMIN_VIEWER",
          ],
          fontIcon: {
            style: "fas",
            icon: "comments",
          },
          show: false,
          child: [
            // {
            //   label: "대시보드",
            //   router: {
            //     name: "ContentDashboard",
            //   },
            // },
            {
              label: "상단 배너",
              router: {
                name: "ContentBannerTop",
              },
            },
            {
              label: "추천팁",
              router: {
                name: "ContentBanner",
              },
            },
            {
              label: "이벤트",
              router: {
                name: "ContentEvent",
              },
            },
            {
              label: "팝업",
              router: {
                name: "ContentPopup",
              },
            },
            {
              label: "축제",
              router: {
                name: "ContentFestival",
              },
            },
            // {
            //   label: "(구)배너",
            //   router: {
            //     name: "ContentBannerOld",
            //   },
            // },
          ],
        },
        {
          label: "페스티벌",
          role: ["ADMIN_MANAGER", "ADMIN_EDITOR", "ADMIN_ANALYST"],
          fontIcon: {
            style: "fas",
            icon: "fire",
          },
          show: false,
          child: [
            {
              label: "결제내역/진행상황",
              router: {
                name: "OrderFestivalPayment",
              },
            },
            {
              label: "축제 자료",
              router: {
                name: "OrderFestivalReference",
              },
            },
            {
              label: "상품",
              router: {
                name: "OrderFestivalShop",
              },
            },
          ],
        },
        {
          label: "시스템",
          role: ["ADMIN_MANAGER"],
          fontIcon: {
            style: "fas",
            icon: "sliders-h",
          },
          show: false,
          child: [
            {
              label: "관리자",
              router: {
                name: "SystemAccount",
              },
            },
            /*
            {
              label: "관리자로그",
              router: {
                name: "SystemAccountLog",
              },
            },
            */
            {
              label: "스탬프 요청",
              router: {
                name: "SystemStampRequest",
              },
            },
            /*
            {
              label: "활동이력",
              router: {
                name: "SystemActvty",
              },
            },
            */
          ],
        },
        {
          label: "광고",
          role: ["ADMIN_MANAGER", "ADMIN_EDITOR", "ADMIN_ANALYST"],
          fontIcon: {
            style: "fas",
            icon: "ad",
          },
          show: false,
          child: [
            // {
            //   label: "대시보드",
            //   router: {
            //     name: "AdvertisementDashboard",
            //   },
            // },
            {
              label: "일반 광고",
              router: {
                name: "AdvertisementAdvertisementGeneral",
              },
            },
            // {
            //   label: "서드파티 광고",
            //   router: {
            //     name: "AdvertisementAdvertisementThirdParty",
            //   },
            // },
          ],
        },
        {
          label: "그룹",
          role: ["ADMIN_MANAGER", "ADMIN_EDITOR", "ADMIN_ANALYST"],
          fontIcon: {
            style: "fas",
            icon: "layer-group",
          },
          show: false,
          child: [
            // {
            //   label: "(구)카테고리 그룹",
            //   router: {
            //     name: "GroupGroupCategoryOld",
            //   },
            // },
            {
              label: "카테고리 그룹",
              router: {
                name: "GroupGroupCategory",
              },
            },
            // {
            //   label: "(구)행정구역 그룹",
            //   router: {
            //     name: "GroupGroupDistrictsOld",
            //   },
            // },
            {
              label: "행정구역 그룹",
              router: {
                name: "GroupGroupDistricts",
              },
            },
          ],
        },
        {
          label: "입점&제휴문의",
          role: [
            "ADMIN_MANAGER",
            "ADMIN_EDITOR",
            "ADMIN_ANALYST",
            "ADMIN_VIEWER",
          ],
          fontIcon: {
            style: "fas",
            icon: "envelope",
          },
          router: {
            name: "AffiliateInquiry",
          },
        },
        {
          label: "문제신고",
          role: [
            "ADMIN_MANAGER",
            "ADMIN_EDITOR",
            "ADMIN_ANALYST",
            "ADMIN_VIEWER",
          ],
          fontIcon: {
            style: "fas",
            icon: "flag",
          },
          router: {
            name: "Report",
          },
        },
        /*
        {
          label: "챗봇",
          fontIcon: {
            style: "fas",
            icon: "robot",
          },
          show: false,
          child: [
            {
              label: "대시보드",
              router: {
                name: "ChatbotDashboard",
              },
            },
            {
              label: "에이전트",
              router: {
                name: "ChatbotAgent",
              },
            },
            {
              label: "서버적용",
              router: {
                name: "ChatbotServerApply",
              },
            },
            {
              label: "질의응답",
              router: {
                name: "ChatbotQuestion",
              },
            },
            {
              label: "FAQ",
              router: {
                name: "ChatbotFaq",
              },
            },
            {
              label: "답변",
              router: {
                name: "ChatbotAnswer",
              },
            },
            {
              label: "기정의 답변",
              router: {
                name: "ChatbotPreDefinedAnswer",
              },
            },
            {
              label: "대화시나리오",
              router: {
                name: "ChatbotDialogueScenario",
              },
            },
            {
              label: "대화상태",
              router: {
                name: "ChatbotDialogueStatus",
              },
            },
            {
              label: "시뮬레이터",
              router: {
                name: "ChatbotSimulator",
              },
            },
            {
              label: "리뷰관리",
              router: {
                name: "ChatbotReview",
              },
            },
            {
              label: "대화방",
              router: {
                name: "ChatbotDialogueRoom",
              },
            },
          ],
        },
        */
      ],
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            this.$router.push({
              name: "Login",
            });
          }
        })
        .catch((message) => {
          this.waitRequest = false;
          alert(message);
          /*
          this.$modal.show(
            Alert,
            {
              alert_name: "알림",
              alert_contnet: message
            },
            {
              height: "auto"
            },
            {
              "before-open": () => {},
              "before-close": () => {}
            }
          );
          */
        });
    },
    breadcrumb(breadcrumb) {
      // console.log(breadcrumb);
      this.title = breadcrumb[breadcrumb.length - 1].name;
      this.subtitle = "";
      if (1 < breadcrumb.length) {
        breadcrumb.forEach((item, index) => {
          if (index == 0) {
            this.title = item.name;
          } else {
            if (this.subtitle.length == 0) {
              this.subtitle = item.name;
            } else {
              this.subtitle = `${this.subtitle} > ${item.name}`;
            }
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    // XXX 권한별 메뉴 보기 설정
    menusByRole() {
      const menusByRole = this.menus.filter((item) => {
        if (item.role.find((r) => r == this.getUser.role)) {
          return true;
        }
      });
      // console.log(menusByRole);
      return menusByRole;
    },
  },
  watch: {
    $route(to) {
      this.breadcrumb(to.meta.breadcrumb);
      // console.log(from);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/common.scss";
</style>
