import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// product
const productFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/shop/product`, {
    params: params,
    headers: authHeader(),
  });
};

const productFindone = (id) => {
  return http.get(SERVICE_API_URL + `/shop/product/${id}`, {
    headers: authHeader(),
  });
};

const productAdd = (params) => {
  return http.post(SERVICE_API_URL + `/shop/product`, params, {
    headers: authHeader(),
  });
};

const productModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/shop/product/${id}`, params, {
    headers: authHeader(),
  });
};
const productRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/shop/product/${id}`, {
    headers: authHeader(),
  });
};
// order
const orderFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/shop/order`, {
    params: params,
    headers: authHeader(),
  });
};

const orderFindone = (id) => {
  return http.get(SERVICE_API_URL + `/shop/order/${id}`, {
    headers: authHeader(),
  });
};

const orderAdd = (params) => {
  return http.post(SERVICE_API_URL + `/shop/order`, params, {
    headers: authHeader(),
  });
};

const orderModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/shop/order/${id}`, params, {
    headers: authHeader(),
  });
};

const orderRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/shop/order/${id}`, {
    headers: authHeader(),
  });
};
// reference
const referenceFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/shop/order/reference`, {
    params: params,
    headers: authHeader(),
  });
};
const referenceFindone = (id) => {
  return http.get(SERVICE_API_URL + `/shop/order/reference/${id}`, {
    headers: authHeader(),
  });
};
const referenceAdd = (params) => {
  return http.post(SERVICE_API_URL + `/shop/order/reference`, params, {
    headers: authHeader(),
  });
};
const referenceModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/shop/order/reference/${id}`, params, {
    headers: authHeader(),
  });
};

const referenceRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/shop/order/reference/${id}`, {
    headers: authHeader(),
  });
};

export default {
  productFindAll,
  productFindone,
  productAdd,
  productModify,
  productRemove,
  orderFindAll,
  orderFindone,
  orderAdd,
  orderModify,
  orderRemove,
  referenceFindAll,
  referenceFindone,
  referenceAdd,
  referenceModify,
  referenceRemove,
};
