import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// postGeneral
const postGeneralFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/post/general`, {
    params: params,
    headers: authHeader(),
  });
};

const postFindone = (id) => {
  return http.get(SERVICE_API_URL + `/post/${id}`, {
    headers: authHeader(),
  });
};
const postFindoneRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/post/${id}`, {
    headers: authHeader(),
  });
};

const postGeneralAdd = (params) => {
  return http.post(SERVICE_API_URL + `/post/general`, params, {
    headers: authHeader(),
  });
};

const postGeneralModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/post/general/${id}`, params, {
    headers: authHeader(),
  });
};

const postGeneralRemove = (id) => {
  return http.put(SERVICE_API_URL + `/post/general/${id}`, {
    headers: authHeader(),
  });
};

// postStamplog
const postStamplogFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/post/stamplog`, {
    params: params,
    headers: authHeader(),
  });
};

const postStamplogFindone = (id) => {
  return http.get(SERVICE_API_URL + `/post/stamplog/${id}`, {
    headers: authHeader(),
  });
};

const postStamplogAdd = (params) => {
  return http.post(SERVICE_API_URL + `/post/stamplog`, params, {
    headers: authHeader(),
  });
};

const postStamplogModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/post/stamplog/${id}`, params, {
    headers: authHeader(),
  });
};

const postStamplogRemove = (id) => {
  return http.put(SERVICE_API_URL + `/post/stamplog/${id}`, {
    headers: authHeader(),
  });
};

// comment
const commentFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/comment`, {
    params: params,
    headers: authHeader(),
  });
};

const commentFindone = (id) => {
  return http.get(SERVICE_API_URL + `/comment/${id}`, {
    headers: authHeader(),
  });
};

const commentAdd = (params) => {
  return http.post(SERVICE_API_URL + `/comment`, params, {
    headers: authHeader(),
  });
};

const commentModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/comment/${id}`, params, {
    headers: authHeader(),
  });
};

const commentRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/comment/${id}`, {
    headers: authHeader(),
  });
};
const commentPostFindone = (id) => {
  return http.get(SERVICE_API_URL + `/comment/post/${id}`, {
    headers: authHeader(),
  });
};
// like
const likeFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/like`, {
    params: params,
    headers: authHeader(),
  });
};

const likeFindone = (id) => {
  return http.get(SERVICE_API_URL + `/like/${id}`, {
    headers: authHeader(),
  });
};

const likeAdd = (params) => {
  return http.post(SERVICE_API_URL + `/like`, params, {
    headers: authHeader(),
  });
};

const likeModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/like/${id}`, params, {
    headers: authHeader(),
  });
};

const likeRemove = (id) => {
  return http.put(SERVICE_API_URL + `/like/${id}`, {
    headers: authHeader(),
  });
};

const likePostId = (id) => {
  return http.get(SERVICE_API_URL + `/like/post/${id}`, {
    headers: authHeader(),
  });
};

// share
const shareFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/share`, {
    params: params,
    headers: authHeader(),
  });
};

const shareFindone = (id) => {
  return http.get(SERVICE_API_URL + `/share/${id}`, {
    headers: authHeader(),
  });
};

const shareAdd = (params) => {
  return http.post(SERVICE_API_URL + `/share`, params, {
    headers: authHeader(),
  });
};

const shareModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/share/${id}`, params, {
    headers: authHeader(),
  });
};

const shareRemove = (id) => {
  return http.put(SERVICE_API_URL + `/share/${id}`, {
    headers: authHeader(),
  });
};

// 통계 statistics
const postRegisterDaily = (params) => {
  return http.get(SERVICE_API_URL + `/post/statistics-register-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const commentRegisterDaily = (params) => {
  return http.get(SERVICE_API_URL + `/comment/statistics-register-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const likeRegisterDaily = (params) => {
  return http.get(SERVICE_API_URL + `/like/statistics-register-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const reportPostDaily = (params) => {
  return http.get(SERVICE_API_URL + `/report/statistics-register-daily`, {
    params: params,
    headers: authHeader(),
  });
};
const reportCommentDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/report/statistics-comment-register-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const postGenderDaily = (params) => {
  return http.get(SERVICE_API_URL + `/post/statistics-register-gender-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const postCountryDaily = (params) => {
  return http.get(SERVICE_API_URL + `/post/statistics-register-country-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const postLangDaily = (params) => {
  return http.get(SERVICE_API_URL + `/post/statistics-register-lang-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const postAgeDaily = (params) => {
  return http.get(SERVICE_API_URL + `/post/statistics-register-age-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const commentGenderDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/comment/statistics-register-gender-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const commentCountryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/comment/statistics-register-country-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const commentLangDaily = (params) => {
  return http.get(SERVICE_API_URL + `/comment/statistics-register-lang-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const commentAgeDaily = (params) => {
  return http.get(SERVICE_API_URL + `/comment/statistics-register-age-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const likeGenderDaily = (params) => {
  return http.get(SERVICE_API_URL + `/like/statistics-register-gender-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const likeCountryDaily = (params) => {
  return http.get(SERVICE_API_URL + `/like/statistics-register-country-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const likeLangDaily = (params) => {
  return http.get(SERVICE_API_URL + `/like/statistics-register-lang-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const likeAgeDaily = (params) => {
  return http.get(SERVICE_API_URL + `/like/statistics-register-age-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const reportGenderDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/report/statistics-register-gender-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const reportCountryDaily = (params) => {
  return http.get(
    SERVICE_API_URL + `/report/statistics-register-country-daily`,
    {
      params: params,
      headers: authHeader(),
    }
  );
};

const reportLangDaily = (params) => {
  return http.get(SERVICE_API_URL + `/report/statistics-register-lang-daily`, {
    params: params,
    headers: authHeader(),
  });
};

const reportAgeDaily = (params) => {
  return http.get(SERVICE_API_URL + `/report/statistics-register-age-daily`, {
    params: params,
    headers: authHeader(),
  });
};

export default {
  postGeneralFindAll,
  postFindone,
  postFindoneRemove,
  postGeneralAdd,
  postGeneralModify,
  postGeneralRemove,
  postStamplogFindAll,
  postStamplogFindone,
  postStamplogAdd,
  postStamplogModify,
  postStamplogRemove,
  commentFindAll,
  commentFindone,
  commentAdd,
  commentModify,
  commentRemove,
  commentPostFindone,
  likeFindAll,
  likeFindone,
  likeAdd,
  likeModify,
  likeRemove,
  likePostId,
  shareFindAll,
  shareFindone,
  shareAdd,
  shareModify,
  shareRemove,
  postRegisterDaily,
  commentRegisterDaily,
  likeRegisterDaily,
  reportPostDaily,
  reportCommentDaily,
  postGenderDaily,
  postCountryDaily,
  postLangDaily,
  postAgeDaily,
  commentGenderDaily,
  commentCountryDaily,
  commentLangDaily,
  commentAgeDaily,
  likeGenderDaily,
  likeCountryDaily,
  likeLangDaily,
  likeAgeDaily,
  reportGenderDaily,
  reportCountryDaily,
  reportLangDaily,
  reportAgeDaily,
};
