import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const inquiryFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/inquiry`, {
    params: params,
    headers: authHeader(),
  });
};

const inquiryFindone = (id) => {
  return http.get(SERVICE_API_URL + `/inquiry/${id}`, {
    headers: authHeader(),
  });
};

const inquiryModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/inquiry/${id}`, params, {
    headers: authHeader(),
  });
};

const inquiryRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/inquiry/${id}`, {
    headers: authHeader(),
  });
};

export default {
  inquiryFindAll,
  inquiryFindone,
  inquiryModify,
  inquiryRemove,
};
