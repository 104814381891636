import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import language from "@/common/language";
import VueProgressBar from "vue-progressbar";

Vue.config.productionTip = false;

// plugins
import AdminDashboard from "./plugins/admin-dashboard";
Vue.use(AdminDashboard);

// moment
import moment from "moment";
moment.locale(language.getLanguage());
Vue.prototype.moment = moment;

// i18n
import i18n from "./i18n";
i18n.locale = language.getLanguage();

// vee-validate
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
for (let rule in rules) {
  extend(rule, rules[rule]);
}
import en from "vee-validate/dist/locale/en.json";
import ko from "vee-validate/dist/locale/ko.json";
localize({
  en,
  ko,
});
localize(language.getLanguage());
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { faScroll } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { faAd } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser,
  faUsers,
  faMapMarker,
  faScroll,
  faComment,
  faComments,
  faSlidersH,
  faAd,
  faEnvelope,
  faFlag,
  faRobot,
  faColumns,
  faLayerGroup,
  faTrashAlt,
  faEdit,
  faChevronLeft,
  faChevronRight,
  faFire,
  faSortDown,
  faSortUp,
  faSort,
  faChartBar,
  faDownload
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// vue-progressbar
const options = {
  color: "#386cfa",
  failedColor: "#d62923",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, options);

// vue-js-modal
import VModal from "vue-js-modal";
Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

// v-calendar
import DatePicker from "v-calendar/lib/components/date-picker.umd";
Vue.component("date-picker", DatePicker);

// vue-spinner
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
Vue.component("clip-loader", ClipLoader);

// vue-month-picker
import { MonthPicker, MonthPickerInput } from "vue-month-picker";
Vue.component("month-picker", MonthPicker);
Vue.component("month-picker-input", MonthPickerInput);

// vue-dayjs-plugin
import VueDayjs from "vue-dayjs-plugin";
Vue.use(VueDayjs);

// TOAST UI Editor
import "@toast-ui/editor/dist/i18n/ko-kr";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor, Viewer } from "@toast-ui/vue-editor";
Vue.component("editor", Editor);
Vue.component("viewer", Viewer);

// css
import "./assets/css/reset.css";
import "./assets/css/style.css";

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
