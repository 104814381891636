import Vue from "vue";
import VueRouter from "vue-router";

// layout
import BasicLayout from "../layout/BasicLayout.vue";
import AuthLayout from "../layout/AuthLayout.vue";

// store
import store from "../store";

let title = "스탬프팝 관리도구";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          authRequired: false,
          title: title + "로그인",
          breadcrumb: [{ name: "", link: "" }],
        },
        component: () => import("../views/Login/index.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    redirect: "/dashboard",
    component: BasicLayout,
    children: [
      // dashboard
      {
        path: "/statistics",
        name: "Statistics",
        meta: {
          authRequired: true,
          title: title + "통계관리",
          breadcrumb: [{ name: "통계관리", link: "" }],
        },
        component: () => import("../views/Statistics/index.vue"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          authRequired: true,
          title: title + "대시보드",
          breadcrumb: [{ name: "대시보드", link: "" }],
        },
        component: () => import("../views/Dashboard/index.vue"),
      },

      // user
      {
        path: "/user/dashboard",
        name: "UserDashboard",
        meta: {
          authRequired: true,
          title: title + "회원 - 대시보드",
          breadcrumb: [
            { name: "회원", link: "" },
            { name: "대시보드", link: "/user/dashboard" },
          ],
        },
        component: () => import("../views/User/Dashboard/index.vue"),
      },
      {
        path: "/user/user-general",
        name: "UserUserGeneral",
        meta: {
          authRequired: true,
          title: title + "회원 - 일반 회원",
          breadcrumb: [
            { name: "회원", link: "" },
            { name: "일반 회원", link: "/user/user-general" },
          ],
        },
        component: () => import("../views/User/UserGeneral/index.vue"),
      },
      {
        path: "/user/user-client",
        name: "UserUserClient",
        meta: {
          authRequired: true,
          title: title + "회원 - 지자체 회원",
          breadcrumb: [
            { name: "회원", link: "" },
            { name: "지자체 회원", link: "/user/user-client" },
          ],
        },
        component: () => import("../views/User/UserClient/index.vue"),
      },
      // stamp
      {
        path: "/stamp/dashboard",
        name: "StampDashboard",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 대시보드",
          breadcrumb: [
            { name: "스탬프", link: "" },
            { name: "대시보드", link: "/stamp/dashboard" },
          ],
        },
        component: () => import("../views/Stamp/Dashboard/index.vue"),
      },
      {
        path: "/stamp/stamp-general",
        name: "StampStampGeneral",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 일반 스탬프",
          breadcrumb: [
            { name: "스탬프", link: "" },
            { name: "일반 스탬프", link: "/stamp/stamp-general" },
          ],
        },
        component: () => import("../views/Stamp/StampGeneral/index.vue"),
      },
      {
        path: "/stamp/stamp-reward",
        name: "StampStampReward",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 배지 스탬프",
          breadcrumb: [
            { name: "스탬프", link: "" },
            { name: "배지 스탬프", link: "/stamp/stamp-reward" },
          ],
        },
        component: () => import("../views/Stamp/StampReward/index.vue"),
      },
      {
        path: "/stamp/acquisition-present-general",
        name: "StampAcquisitionPresentGeneral",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 일반 스탬프 획득 현황",
          breadcrumb: [
            { name: "스탬프", link: "" },
            {
              name: "일반 스탬프 획득 현황",
              link: "/stamp/acquisition-present-general",
            },
          ],
        },
        component: () =>
          import("../views/Stamp/AcquisitionPresentGeneral/index.vue"),
      },
      {
        path: "/stamp/acquisition-present-reward",
        name: "StampAcquisitionPresentReward",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 배지 스탬프 획득 현황",
          breadcrumb: [
            { name: "스탬프", link: "" },
            {
              name: "배지 스탬프 획득 현황",
              link: "/stamp/acquisition-present-reward",
            },
          ],
        },
        component: () =>
          import("../views/Stamp/AcquisitionPresentReward/index.vue"),
      },
      {
        path: "/stamp/acquisition-group-present-general",
        name: "StampAcquisitionGroupPresentGeneral",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 일반 스탬프 별 획득 현황",
          breadcrumb: [
            { name: "스탬프", link: "" },
            {
              name: "일반 스탬프 별 획득 현황",
              link: "/stamp/acquisition-group-present-general",
            },
          ],
        },
        component: () =>
          import("../views/Stamp/AcquisitionGroupPresentGeneral/index.vue"),
      },
      {
        path: "/stamp/acquisition-group-present-reward",
        name: "StampAcquisitionGroupPresentReward",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 배지 스탬프 별 획득 현황",
          breadcrumb: [
            { name: "스탬프", link: "" },
            {
              name: "배지 스탬프 별 획득 현황",
              link: "/stamp/acquisition-group-present-reward",
            },
          ],
        },
        component: () =>
          import("../views/Stamp/AcquisitionGroupPresentReward/index.vue"),
      },
      {
        path: "/stamp/acquisition-client-present",
        name: "StampAcquisitionClientPresent",
        meta: {
          authRequired: true,
          title: title + "스탬프 - 지자체 스탬프 획득 현황",
          breadcrumb: [
            { name: "스탬프", link: "" },
            {
              name: "지자체 스탬프 획득 현황",
              link: "/stamp/acquisition-client-present",
            },
          ],
        },
        component: () =>
          import("../views/Stamp/AcquisitionClientPresent/index.vue"),
      },
      // mission
      {
        path: "/mission/dashboard",
        name: "MissionDashboard",
        meta: {
          authRequired: true,
          title: title + "미션 - 대시보드",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "대시보드",
              link: "/mission/dashboard",
            },
          ],
        },
        component: () => import("../views/Mission/Dashboard/index.vue"),
      },
      {
        path: "/mission/mission-general",
        name: "MissionMissionGeneral",
        meta: {
          authRequired: true,
          title: title + "미션 - 일반 미션",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "일반 미션",
              link: "/mission/mission-general",
            },
          ],
        },
        component: () => import("../views/Mission/MissionGeneral/index.vue"),
      },
      {
        path: "/mission/mission-reward",
        name: "MissionMissionReward",
        meta: {
          authRequired: true,
          title: title + "미션 - 배지 미션",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "배지 미션",
              link: "/mission/mission-reward",
            },
          ],
        },
        component: () => import("../views/Mission/MissionReward/index.vue"),
      },
      {
        path: "/mission/mission-complete-present-general",
        name: "MissionMissionCompletePresentGeneral",
        meta: {
          authRequired: true,
          title: title + "미션 - 일반 미션 완료 현황",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "일반 미션 완료 현황",
              link: "/mission/mission-complete-present-general",
            },
          ],
        },
        component: () =>
          import("../views/Mission/MissionCompletePresentGeneral/index.vue"),
      },
      {
        path: "/mission/mission-complete-present-reward",
        name: "MissionMissionCompletePresentReward",
        meta: {
          authRequired: true,
          title: title + "미션 - 배지 미션 완료 현황",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "배지 미션 완료 현황",
              link: "/mission/mission-complete-present-reward",
            },
          ],
        },
        component: () =>
          import("../views/Mission/MissionCompletePresentReward/index.vue"),
      },
      {
        path: "/mission/mission-complete-group-present-general",
        name: "MissionMissionCompleteGroupPresentGeneral",
        meta: {
          authRequired: true,
          title: title + "미션 - 일반 미션별 완료 현황",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "일반 미션별 완료 현황",
              link: "/mission/mission-complete-group-present-general",
            },
          ],
        },
        component: () =>
          import(
            "../views/Mission/MissionCompleteGroupPresentGeneral/index.vue"
          ),
      },
      {
        path: "/mission/mission-complete-group-present-reward",
        name: "MissionMissionCompleteGroupPresentReward",
        meta: {
          authRequired: true,
          title: title + "미션 - 배지 미션별 완료 현황",
          breadcrumb: [
            { name: "미션", link: "" },
            {
              name: "배지 미션별 완료 현황",
              link: "/mission/mission-complete-group-present-reward",
            },
          ],
        },
        component: () =>
          import(
            "../views/Mission/MissionCompleteGroupPresentReward/index.vue"
          ),
      },
      // community
      {
        path: "/community/dashboard",
        name: "CommunityDashboard",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 대시보드",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "대시보드",
              link: "/community/dashboard",
            },
          ],
        },
        component: () => import("../views/Community/Dashboard/index.vue"),
      },
      {
        path: "/community/post-general",
        name: "CommunityPostGeneral",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 일반 게시글",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "일반 게시글",
              link: "/community/post-general",
            },
          ],
        },
        component: () => import("../views/Community/PostGeneral/index.vue"),
      },
      {
        path: "/community/post-stamp-log",
        name: "CommunityPostStampLog",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 스탬프 로그 게시글",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "스탬프 로그 게시글",
              link: "/community/post-stamp-log",
            },
          ],
        },
        component: () => import("../views/Community/PostStampLog/index.vue"),
      },
      {
        path: "/community/comment",
        name: "CommunityComment",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 댓글",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "댓글",
              link: "/community/comment",
            },
          ],
        },
        component: () => import("../views/Community/Comment/index.vue"),
      },
      {
        path: "/community/like",
        name: "CommunityLike",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 좋아요",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "좋아요",
              link: "/community/like",
            },
          ],
        },
        component: () => import("../views/Community/Like/index.vue"),
      },
      {
        path: "/community/share",
        name: "CommunityShare",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 공유",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "공유",
              link: "/community/share",
            },
          ],
        },
        component: () => import("../views/Community/Share/index.vue"),
      },
      {
        path: "/community/report",
        name: "CommunityReport",
        meta: {
          authRequired: true,
          title: title + "커뮤니티 - 문제신고",
          breadcrumb: [
            { name: "커뮤니티", link: "" },
            {
              name: "문제신고",
              link: "/community/report",
            },
          ],
        },
        component: () => import("../views/Community/Report/index.vue"),
      },
      // content
      {
        path: "/content/dashboard",
        name: "ContentDashboard",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - 대시보드",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "대시보드",
              link: "/content/dashboard",
            },
          ],
        },
        component: () => import("../views/Content/Dashboard/index.vue"),
      },
      {
        path: "/content/banner-top",
        name: "ContentBannerTop",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - 상단 배너",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "상단 배너",
              link: "/content/banner-top",
            },
          ],
        },
        component: () => import("../views/Content/BannerTop/index.vue"),
      },
      {
        path: "/content/banner-old",
        name: "ContentBannerOld",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - (구)배너",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "(구)배너",
              link: "/content/banner-old",
            },
          ],
        },
        component: () => import("../views/Content/BannerOld/index.vue"),
      },
      {
        path: "/content/banner",
        name: "ContentBanner",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - 추천팁",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "추천팁",
              link: "/content/banner",
            },
          ],
        },
        component: () => import("../views/Content/Banner/index.vue"),
      },
      {
        path: "/content/event",
        name: "ContentEvent",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - 이벤트",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "이벤트",
              link: "/content/event",
            },
          ],
        },
        component: () => import("../views/Content/Event/index.vue"),
      },
      {
        path: "/content/popup",
        name: "ContentPopup",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - 팝업",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "팝업",
              link: "/content/popup",
            },
          ],
        },
        component: () => import("../views/Content/Popup/index.vue"),
      },
      {
        path: "/content/festival",
        name: "ContentFestival",
        meta: {
          authRequired: true,
          title: title + "콘텐츠 - 축제",
          breadcrumb: [
            { name: "콘텐츠", link: "" },
            {
              name: "축제",
              link: "/content/festival",
            },
          ],
        },
        component: () => import("../views/Content/Festival/index.vue"),
      },
      // festival
      {
        path: "/order/festival-payment",
        name: "OrderFestivalPayment",
        meta: {
          authRequired: true,
          title: title + "페스티벌 - 결제내역/진행현황",
          breadcrumb: [
            { name: "페스티벌", link: "" },
            {
              name: "결제내역/진행현황",
              link: "/order/festival-payment",
            },
          ],
        },
        component: () => import("../views/Order/FestivalPayment/index.vue"),
      },
      {
        path: "/order/festival-reference",
        name: "OrderFestivalReference",
        meta: {
          authRequired: true,
          title: title + "페스티벌 - 축제자료",
          breadcrumb: [
            { name: "페스티벌", link: "" },
            {
              name: "축제자료",
              link: "/order/festival-reference",
            },
          ],
        },
        component: () => import("../views/Order/FestivalReference/index.vue"),
      },
      {
        path: "/order/festival-shop",
        name: "OrderFestivalShop",
        meta: {
          authRequired: true,
          title: title + "페스티벌 - 상품",
          breadcrumb: [
            { name: "페스티벌", link: "" },
            {
              name: "상품",
              link: "/order/festival-shop",
            },
          ],
        },
        component: () => import("../views/Order/Shop/index.vue"),
      },
      // system
      {
        path: "/system/account",
        name: "SystemAccount",
        meta: {
          authRequired: true,
          title: title + "시스템 - 관리자",
          breadcrumb: [
            { name: "시스템", link: "" },
            {
              name: "관리자",
              link: "/system/account",
            },
          ],
        },
        component: () => import("../views/System/Account/index.vue"),
      },
      {
        path: "/system/account-log",
        name: "SystemAccountLog",
        meta: {
          authRequired: true,
          title: title + "시스템 - 관리자로그",
          breadcrumb: [
            { name: "시스템", link: "" },
            {
              name: "관리자로그",
              link: "/system/account-log",
            },
          ],
        },
        component: () => import("../views/System/AccountLog/index.vue"),
      },
      {
        path: "/system/stamp-request",
        name: "SystemStampRequest",
        meta: {
          authRequired: true,
          title: title + "시스템 - 스탬프요청",
          breadcrumb: [
            { name: "시스템", link: "" },
            {
              name: "스탬프요청",
              link: "/system/stamp-request",
            },
          ],
        },
        component: () => import("../views/System/StampRequest/index.vue"),
      },
      {
        path: "/system/actvty",
        name: "SystemActvty",
        meta: {
          authRequired: true,
          title: title + "시스템 - 활동이력",
          breadcrumb: [
            { name: "시스템", link: "" },
            {
              name: "활동이력",
              link: "/system/actvty",
            },
          ],
        },
        component: () => import("../views/System/Actvty/index.vue"),
      },
      // advertisement
      {
        path: "/advertisement/dashboard",
        name: "AdvertisementDashboard",
        meta: {
          authRequired: true,
          title: title + "광고 - 대시보드",
          breadcrumb: [
            { name: "광고", link: "" },
            {
              name: "대시보드",
              link: "/advertisement/dashboard",
            },
          ],
        },
        component: () => import("../views/Advertisement/Dashboard/index.vue"),
      },
      {
        path: "/advertisement/advertisement-general",
        name: "AdvertisementAdvertisementGeneral",
        meta: {
          authRequired: true,
          title: title + "광고 - 일반 광고",
          breadcrumb: [
            { name: "광고", link: "" },
            {
              name: "일반 광고",
              link: "/advertisement/advertisement-general",
            },
          ],
        },
        component: () =>
          import("../views/Advertisement/AdvertisementGeneral/index.vue"),
      },
      {
        path: "/advertisement/advertisement-third-party",
        name: "AdvertisementAdvertisementThirdParty",
        meta: {
          authRequired: true,
          title: title + "광고 - 서드파티 광고",
          breadcrumb: [
            { name: "광고", link: "" },
            {
              name: "서드파티 광고",
              link: "/advertisement/advertisement-third-party",
            },
          ],
        },
        component: () =>
          import("../views/Advertisement/AdvertisementThirdParty/index.vue"),
      },
      // group
      {
        path: "/group/group-category-old",
        name: "GroupGroupCategoryOld",
        meta: {
          authRequired: true,
          title: title + "그룹 - (구)카테고리 그룹",
          breadcrumb: [
            { name: "그룹", link: "" },
            {
              name: "(구)카테고리 그룹",
              link: "/group/group-category-old",
            },
          ],
        },
        component: () => import("../views/Group/GroupCategoryOld/index.vue"),
      },
      {
        path: "/group/group-category",
        name: "GroupGroupCategory",
        meta: {
          authRequired: true,
          title: title + "그룹 - 카테고리 그룹",
          breadcrumb: [
            { name: "그룹", link: "" },
            {
              name: "카테고리 그룹",
              link: "/group/group-category",
            },
          ],
        },
        component: () => import("../views/Group/GroupCategory/index.vue"),
      },
      {
        path: "/group/group-districts-old",
        name: "GroupGroupDistrictsOld",
        meta: {
          authRequired: true,
          title: title + "그룹 - (구)행정구역 그룹",
          breadcrumb: [
            { name: "그룹", link: "" },
            {
              name: "행정구역 그룹",
              link: "/group/group-districts-old",
            },
          ],
        },
        component: () => import("../views/Group/GroupDistrictsOld/index.vue"),
      },
      {
        path: "/group/group-districts",
        name: "GroupGroupDistricts",
        meta: {
          authRequired: true,
          title: title + "그룹 - 행정구역 그룹",
          breadcrumb: [
            { name: "그룹", link: "" },
            {
              name: "행정구역 그룹",
              link: "/group/group-districts",
            },
          ],
        },
        component: () => import("../views/Group/GroupDistricts/index.vue"),
      },
      // affiliate-inquiry
      {
        path: "/affiliate-inquiry",
        name: "AffiliateInquiry",
        meta: {
          authRequired: true,
          title: title + "입점&제휴문의",
          breadcrumb: [{ name: "입점&제휴문의", link: "/affiliate-inquiry" }],
        },
        component: () => import("../views/AffiliateInquiry/index.vue"),
      },
      // report
      {
        path: "/report",
        name: "Report",
        meta: {
          authRequired: true,
          title: title + "문제신고",
          breadcrumb: [{ name: "문제신고", link: "/report" }],
        },
        component: () => import("../views/Report/index.vue"),
      },
      // chatbot
      {
        path: "/chatbot/dashboard",
        name: "ChatbotDashboard",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 대시보드",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "대시보드",
              link: "/chatbot/dashboard",
            },
          ],
        },
        component: () => import("../views/Chatbot/Dashboard/index.vue"),
      },
      {
        path: "/chatbot/agent",
        name: "ChatbotAgent",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 에이전트",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "에이전트",
              link: "/chatbot/agent",
            },
          ],
        },
        component: () => import("../views/Chatbot/Agent/index.vue"),
      },
      {
        path: "/chatbot/server-apply",
        name: "ChatbotServerApply",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 서버적용",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "서버적용",
              link: "/chatbot/server-apply",
            },
          ],
        },
        component: () => import("../views/Chatbot/ServerApply/index.vue"),
      },
      {
        path: "/chatbot/question",
        name: "ChatbotQuestion",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 질의응답",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "질의응답",
              link: "/chatbot/question",
            },
          ],
        },
        component: () => import("../views/Chatbot/Question/index.vue"),
      },
      {
        path: "/chatbot/faq",
        name: "ChatbotFaq",
        meta: {
          authRequired: true,
          title: title + "챗봇 - FAQ",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "FAQ",
              link: "/chatbot/faq",
            },
          ],
        },
        component: () => import("../views/Chatbot/Faq/index.vue"),
      },
      {
        path: "/chatbot/answer",
        name: "ChatbotAnswer",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 답변",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "답변",
              link: "/chatbot/answer",
            },
          ],
        },
        component: () => import("../views/Chatbot/Answer/index.vue"),
      },
      {
        path: "/chatbot/pre-defined-answer",
        name: "ChatbotPreDefinedAnswer",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 기정의 답변",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "기정의 답변",
              link: "/chatbot/pre-defined-answer",
            },
          ],
        },
        component: () => import("../views/Chatbot/PreDefinedAnswer/index.vue"),
      },
      {
        path: "/chatbot/dialogue-scenario",
        name: "ChatbotDialogueScenario",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 대화시나리오",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "대화시나리오",
              link: "/chatbot/dialogue-scenario",
            },
          ],
        },
        component: () => import("../views/Chatbot/DialogueScenario/index.vue"),
      },
      {
        path: "/chatbot/dialogue-status",
        name: "ChatbotDialogueStatus",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 대화상태",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "대화상태",
              link: "/chatbot/dialogue-status",
            },
          ],
        },
        component: () => import("../views/Chatbot/DialogueStatus/index.vue"),
      },
      {
        path: "/chatbot/simulator",
        name: "ChatbotSimulator",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 시뮬레이터",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "시뮬레이터",
              link: "/chatbot/simulator",
            },
          ],
        },
        component: () => import("../views/Chatbot/Simulator/index.vue"),
      },
      {
        path: "/chatbot/review",
        name: "ChatbotReview",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 리뷰관리",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "리뷰관리",
              link: "/chatbot/review",
            },
          ],
        },
        component: () => import("../views/Chatbot/Review/index.vue"),
      },
      {
        path: "/chatbot/dialogue-room",
        name: "ChatbotDialogueRoom",
        meta: {
          authRequired: true,
          title: title + "챗봇 - 대화방",
          breadcrumb: [
            { name: "챗봇", link: "" },
            {
              name: "대화방",
              link: "/chatbot/dialogue-room",
            },
          ],
        },
        component: () => import("../views/Chatbot/DialogueRoom/index.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      authRequired: true,
      title: title + "NotFound",
      breadcrumb: [{ name: "NotFound", link: "*" }],
    },
    component: () => import("../views/NotFound/index.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["isAuthenticated"];
  console.log(from);
  if (
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    // 인증이 필요한 페이지일 경우 인증 체크
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    if (isAuthenticated) {
      next({ name: "UserUserGeneral" });
      // next({ name: "Dashboard" });
    } else {
      next();
    }
  }
});

export default router;
