<template>
  <div class="gnb">
    <h1 class="logo">
      <a v-if="logo.text" :href="logo.url ? logo.url : '/'">
        <img
          v-if="logo.src"
          :src="logo.src"
          style="width: 165.2px; height: 30px"
          :alt="logo.text"
        />
        <template v-else>
          {{ logo.text }}
        </template>
      </a>
    </h1>
    <!-- menu -->
    <div class="menu">
      <ul>
        <li
          :class="['main_menu', { main_solo: !item.child }]"
          v-for="(item, index) in menus"
          :key="`menu-${index}`"
        >
          <template v-if="item.router">
            <a
              class="main_title"
              v-if="item.router.url"
              :href="item.router.url"
            >
              <!-- <img
                v-if="item.imgIcon"
                :src="item.imgIcon"
                style="width: 16px; height: 16px"
                class="gnb_icon"
                :alt="item.label"
              /> -->
              <font-awesome-icon
                class="gnb_icon"
                v-if="item.fontIcon"
                :icon="[item.fontIcon['style'], item.fontIcon['icon']]"
              />
              {{ item.label }}
            </a>
            <router-link
              v-else-if="item.router.name"
              class="main_title"
              :to="{ name: item.router.name }"
            >
              <img
                v-if="item.imgIcon"
                :src="item.imgIcon"
                style="width: 16px; height: 16px"
                class="gnb_icon"
                :alt="item.label"
              />
              <font-awesome-icon
                class="gnb_icon"
                v-else-if="item.fontIcon"
                :icon="[item.fontIcon.style, item.fontIcon.icon]"
              />

              {{ item.label }}</router-link
            >
          </template>
          <template v-else-if="item.child">
            <a
              class="main_title"
              href="#"
              @click.prevent.stop="onMenuToggle(item)"
            >
              <!-- <img
                v-if="item.imgIcon"
                :src="item.imgIcon"
                style="width: 16px; height: 16px"
                class="gnb_icon"
                :alt="item.label" /> -->
              <font-awesome-icon
                class="gnb_icon"
                v-if="item.fontIcon"
                :icon="[item.fontIcon.style, item.fontIcon.icon]" />
              {{ item.label
              }}<span
                :class="[
                  { main_btn_off: item.child && !item.show },
                  { main_btn_on: item.child && item.show },
                ]"
              ></span
            ></a>
          </template>

          <transition
            name="expand"
            @enter="expandEnter"
            @afterEnter="expandAfterEnter"
            @beforeLeave="expandBeforeLeave"
          >
            <ul v-if="item.child && item.show" class="sub">
              <li
                v-for="(childItem, childIndex) in item.child"
                :key="`menu-${childIndex}`"
                class="sub_item"
              >
                <template v-if="childItem.router">
                  <a v-if="childItem.router.url" :href="childItem.router.url">
                    <img
                      v-if="childItem.imgIcon"
                      :src="item.imgIcon"
                      style="width: 16px; height: 16px"
                      class="gnb_icon"
                      :alt="item.label"
                    />
                    <font-awesome-icon
                      class="gnb_icon"
                      v-else-if="item.fontIcon"
                      :icon="[item.fontIcon.style, item.fontIcon.icon]"
                    />
                    {{ childItem.label }}</a
                  >
                  <router-link
                    v-else-if="childItem.router"
                    :class="[{ gnb_on: subIsActive([childItem.router.name]) }]"
                    :to="{ name: childItem.router.name }"
                    ><img
                      v-if="childItem.imgIcon"
                      :src="item.imgIcon"
                      style="width: 16px; height: 16px"
                      class="gnb_icon"
                      :alt="item.label"
                    /><font-awesome-icon
                      class="gnb_icon"
                      v-else-if="childItem.fontIcon"
                      :icon="[
                        childItem.fontIcon.style,
                        childItem.fontIcon.icon,
                      ]"
                    />{{ childItem.label }}</router-link
                  >
                </template>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * vuex
 */
import { mapGetters } from "vuex";

export default {
  name: "SideBar",
  components: {
    /**
     * components
     */
  },
  props: {
    logo: {
      type: Object,
      default: () => {
        return {
          src: "",
          text: "",
        };
      },
      description: "Logo (default -> src, text null)",
    },
    menus: {
      type: Array,
      default: () => [],
      description: "Menu List (default -> empty array)",
    },
  },
  data() {
    /**
     * data
     */
    return {
      language: {
        value: "",
        items: [
          {
            key: "ko",
            label: this.$t("language_ko"),
            value: "ko",
          },
          {
            key: "en",
            label: this.$t("language_en"),
            value: "en",
          },
        ],
      },
    };
  },
  created() {
    /**
     * created
     */
    this.language.value = this.getLanguage;

    if (this.logo.src) {
      let images = require.context("@/assets/img/", false, /\.png$/);
      this.logo.src = images(`./${this.logo.src}`);
    }

    this.menus.forEach((item) => {
      if (item.imgIcon) {
        // console.log(require);
        // console.log(index);
        let images = require.context("@/assets/img/", false, /\.png$/);
        item.imgIcon = images(`./${item.imgIcon}`);
      }
      if (item.child) {
        if (this.subIsActive(item.child)) {
          item.show = true;
        }
        item.child.forEach((childItem) => {
          if (childItem.imgIcon) {
            // console.log(require);
            // console.log(childIndex);
            let images = require.context("@/assets/img/", false, /\.png$/);
            childItem.imgIcon = images(`./${childItem.imgIcon}`);
          }
        });
      }
    });
  },
  mounted() {
    /**
     * mounted
     */
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["getLanguage"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
    expandEnter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    expandAfterEnter(el) {
      el.style.height = "auto";
    },
    expandBeforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    onMenuToggle(item) {
      if (item.show) {
        item.show = false;
      } else {
        this.menus.forEach((item) => {
          if (item.child) {
            item.show = false;
          }
        });
        item.show = true;
      }
      console.log(item);
    },
    subIsActive(input) {
      let urls = [];
      input.forEach((item) => {
        let url = "";
        let temp = "";
        if (item.router) {
          temp = item.router.name
            .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
              return "-" + y.toLowerCase();
            })
            .replace(/^_/, "")
            .split("-");
        } else {
          temp = item
            .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
              return "-" + y.toLowerCase();
            })
            .replace(/^_/, "")
            .split("-");
        }

        temp.forEach((tempItem, index) => {
          if (tempItem.length != 0) {
            if (index < 3) {
              url = url + "/" + tempItem;
            } else {
              url = url + "-" + tempItem;
            }
          }
        });
        urls.push(url);
      });

      const paths = Array.isArray(urls) ? urls : [urls];
      return paths.some((path) => {
        return path === this.$route.path; // current path starts with this path string
      });
    },
    onLanguage() {
      alert(this.language.value);
      this.$store
        .dispatch("language", {
          language: this.language.value,
        })
        .then((response) => {
          console.log(response);
          // this.$i18n.locale = this.language.value;
          // this.moment.locale(this.language);
        })
        .catch(({ message }) => console.log(message));
    },
  },
};
</script>
