import authService from "./auth.service";
import stampService from "./stamp.service";
import missionService from "./mission.service";
import groupService from "./group.service";
import contentService from "./content.service";
import userService from "./user.service";
import communityService from "./community.service";
import fileService from "./file.service";
import reportService from "./report.service";
import inquiryService from "./inquiry.service";
import advertisementService from "./advertisement.service";
import orderService from "./order.service";

export default {
  // fileService
  fileAdd(params) {
    return fileService.fileAdd(params);
  },
  // authService
  authLogin(params) {
    return authService.login(params);
  },
  authLogout() {
    return authService.logout();
  },
  authUser() {
    return authService.user();
  },
  // STAMPService
  // stampFindOne
  stampFindOne(id) {
    return stampService.stampFindOne(id);
  },
  // stampAdd
  stampAdd(params) {
    return stampService.stampAdd(params);
  },
  // stampModify
  stampModify(id, params) {
    return stampService.stampModify(id, params);
  },
  // stampFindAll
  stampFindAll(params) {
    return stampService.stampFindAll(params);
  },
  // stampRemove
  stampRemove(id, params) {
    return stampService.stampRemove(id, params);
  },
  stampGroupFindone(id) {
    return stampService.stampGroupFindone(id);
  },
  oldStampGroupFindone(id) {
    return stampService.oldStampGroupFindone(id);
  },
  stampMissionFindone(id, params) {
    return stampService.stampMissionFindone(id, params);
  },

  stampReviewFindAll(params) {
    return stampService.stampReviewFindAll(params);
  },

  // stampRequest
  stampRequestFindAll(params) {
    return stampService.stampRequestFindAll(params);
  },

  stampRequestFindOne(id) {
    return stampService.stampRequestFindOne(id);
  },

  stampRequestModify(id, params) {
    return stampService.stampRequestModify(id, params);
  },

  stampRequestRemove(id) {
    return stampService.stampRequestRemove(id);
  },
  // generalStamp
  generalStampFindAll(params) {
    return stampService.generalFindAll(params);
  },
  generalStampFindone(id) {
    return stampService.generalFindone(id);
  },
  generalStampAdd(params) {
    return stampService.generalAdd(params);
  },
  generalStampModify(id, params) {
    return stampService.generalModify(id, params);
  },
  generalStampRemove(id) {
    return stampService.generalRemove(id);
  },
  generalUnregisterFindAll(params) {
    return userService.generalUnregisterFindAll(params);
  },
  generalRegisterFindAll(params) {
    return userService.generalRegisterFindAll(params);
  },
  generalDormantFindAll(params) {
    return userService.generalDormantFindAll(params);
  },
  // rewardStamp
  rewardStampFindAll(params) {
    return stampService.rewardFindAll(params);
  },
  rewardStampFindone(id) {
    return stampService.rewardFindone(id);
  },
  rewardStampAdd(params) {
    return stampService.rewardAdd(params);
  },
  rewardStampModify(id, params) {
    return stampService.rewardModify(id, params);
  },
  rewardStampRemove(id) {
    return stampService.rewardRemove(id);
  },
  // acquisitionGeneralStamp
  acquisitionGeneralStampFindAll(params) {
    return stampService.acquisitionGeneralFindAll(params);
  },
  acquisitionGeneralStampFindone(id) {
    return stampService.acquisitionGeneralFindone(id);
  },
  acquisitionGeneralStampAdd(params) {
    return stampService.acquisitionGeneralAdd(params);
  },
  acquisitionGeneralStampModify(id, params) {
    return stampService.acquisitionGeneralModify(id, params);
  },
  acquisitionGeneralStampRemove(id) {
    return stampService.acquisitionGeneralRemove(id);
  },
  // acquisitionRewardStamp
  acquisitionRewardStampFindAll(params) {
    return stampService.acquisitionRewardFindAll(params);
  },
  acquisitionRewardStampFindone(id) {
    return stampService.acquisitionRewardFindone(id);
  },
  acquisitionRewardStampAdd(params) {
    return stampService.acquisitionRewardAdd(params);
  },
  acquisitionRewardStampModify(id, params) {
    return stampService.acquisitionRewardModify(id, params);
  },
  acquisitionRewardStampRemove(id) {
    return stampService.acquisitionRewardRemove(id);
  },
  // acquisitionGroupGeneralStamp
  acquisitionGroupGeneralStampFindAll(params) {
    return stampService.acquisitionGroupGeneralFindAll(params);
  },
  acquisitionGroupGeneralStampFindone(id) {
    return stampService.acquisitionGroupGeneralFindone(id);
  },
  acquisitionGroupGeneralStampAdd(params) {
    return stampService.acquisitionGroupGeneralAdd(params);
  },
  acquisitionGroupGeneralStampModify(id, params) {
    return stampService.acquisitionGroupGeneralModify(id, params);
  },
  acquisitionGroupGeneralStampRemove(id) {
    return stampService.acquisitionGroupGeneralRemove(id);
  },
  // acquisitionGroupRewardStamp
  acquisitionGroupRewardStampFindAll(params) {
    return stampService.acquisitionGroupRewardFindAll(params);
  },
  acquisitionGroupRewardStampFindone(id) {
    return stampService.acquisitionGroupRewardFindone(id);
  },
  acquisitionGroupRewardStampAdd(params) {
    return stampService.acquisitionGroupRewardAdd(params);
  },
  acquisitionGroupRewardStampModify(id, params) {
    return stampService.acquisitionGroupRewardModify(id, params);
  },
  acquisitionGroupRewardStampRemove(id) {
    return stampService.acquisitionGroupRewardRemove(id);
  },
  // acquisitionClientStamp
  acquisitionClientStampFindAll(params) {
    return stampService.acquisitionClientFindAll(params);
  },
  acquisitionClientStampFindone(id) {
    return stampService.acquisitionClientFindone(id);
  },
  acquisitionClientStampAdd(params) {
    return stampService.acquisitionClientAdd(params);
  },
  acquisitionClientStampModify(id, params) {
    return stampService.acquisitionClientModify(id, params);
  },
  acquisitionClientStampRemove(id) {
    return stampService.acquisitionClientRemove(id);
  },
  // MISSIONService
  // generalMission
  generalMissionFindAll(params) {
    return missionService.generalFindAll(params);
  },
  generalMissionFindone(id) {
    return missionService.generalFindone(id);
  },
  generalMissionAdd(params) {
    return missionService.generalAdd(params);
  },
  generalMissionModify(id, params) {
    return missionService.generalModify(id, params);
  },
  generalMissionRemove(id) {
    return missionService.generalRemove(id);
  },
  // rewardMission
  rewardMissionFindAll(params) {
    return missionService.rewardFindAll(params);
  },
  rewardMissionFindone(id) {
    return missionService.rewardFindone(id);
  },
  rewardMissionAdd(params) {
    return missionService.rewardAdd(params);
  },
  rewardMissionModify(id, params) {
    return missionService.rewardModify(id, params);
  },
  rewardMissionRemove(id) {
    return missionService.rewardRemove(id);
  },
  // missionReviw
  missionReviewFindAll(params) {
    return missionService.missionReviewFindAll(params);
  },
  // missionCompleteGeneral
  missionCompleteGeneralFindAll(params) {
    return missionService.missionCompleteGeneralFindAll(params);
  },
  missionCompleteGeneralFindone(id) {
    return missionService.missionCompleteGeneralFindone(id);
  },
  missionCompleteGeneralAdd(params) {
    return missionService.missionCompleteGeneralAdd(params);
  },
  missionCompleteGeneralModify(id, params) {
    return missionService.missionCompleteGeneralModify(id, params);
  },
  missionCompleteGeneralRemove(id) {
    return missionService.missionCompleteGeneralRemove(id);
  },
  missionCompleteGroupGeneralFindAll(params) {
    return missionService.missionCompleteGroupGeneralFindAll(params);
  },
  // missionCompleteReward
  missionCompleteRewardFindAll(params) {
    return missionService.missionCompleteRewardFindAll(params);
  },
  missionCompleteRewardFindone(id) {
    return missionService.missionCompleteRewardFindone(id);
  },
  missionCompleteRewardAdd(params) {
    return missionService.missionCompleteRewardAdd(params);
  },
  missionCompleteRewardModify(id, params) {
    return missionService.missionCompleteRewardModify(id, params);
  },
  missionCompleteRewardRemove(id) {
    return missionService.missionCompleteRewardRemove(id);
  },
  missionCompleteGroupRewardFindAll(params) {
    return missionService.missionCompleteGroupRewardFindAll(params);
  },
  missionFindAll(params) {
    return missionService.missionFindAll(params);
  },
  // missionFindone
  missionFindone(id) {
    return missionService.missionFindone(id);
  },
  // missionAdd
  missionAdd(params) {
    return missionService.missionAdd(params);
  },
  // missionModify
  missionModify(id, params) {
    return missionService.missionModify(id, params);
  },
  // missionModify
  missionRemove(id) {
    return missionService.missionRemove(id);
  },
  //인증번호 삭제
  resetUserPhone(id, params) {
    return missionService.resetUserPhone(id, params);
  },

  // GROUPService
  groupFindone(id) {
    return groupService.groupFindone(id);
  },
  groupAdd(params) {
    return groupService.groupAdd(params);
  },
  groupModify(id, params) {
    return groupService.groupModify(id, params);
  },
  groupRemove(params) {
    return groupService.groupRemove(params);
  },
  // groupOld
  oldGroupFindone(id) {
    return groupService.oldGroupFindone(id);
  },
  oldGroupAdd(params) {
    return groupService.oldGroupAdd(params);
  },
  oldGroupModify(id, params) {
    return groupService.oldGroupModify(id, params);
  },
  oldGroupRemove(params) {
    return groupService.oldGroupRemove(params);
  },
  // category
  categoryGroupFindAll(params) {
    return groupService.categoryFindAll(params);
  },
  oldCategoryGroupFindAll(params) {
    return groupService.oldCategoryFindAll(params);
  },
  // districts
  districtsGroupFindAll(params) {
    return groupService.districtsFindAll(params);
  },
  oldDistrictsGroupFindAll(params) {
    return groupService.oldDistrictsFindAll(params);
  },

  // CONTENTService
  // banner
  bannerFindAll(params) {
    return contentService.bannerFindAll(params);
  },
  bannerFindone(id) {
    return contentService.bannerFindone(id);
  },
  bannerAdd(params) {
    return contentService.bannerAdd(params);
  },
  bannerModify(id, params) {
    return contentService.bannerModify(id, params);
  },
  bannerRemove(id) {
    return contentService.bannerRemove(id);
  },
  // oldBanner
  oldBannerFindAll(params) {
    return contentService.oldBannerFindAll(params);
  },
  oldBannerFindone(id) {
    return contentService.oldBannerFindone(id);
  },
  oldBannerAdd(params) {
    return contentService.oldBannerAdd(params);
  },
  oldBannerModify(id, params) {
    return contentService.oldBannerModify(id, params);
  },
  oldBannerRemove(id) {
    return contentService.oldBannerRemove(id);
  },
  // banner-top
  bannerTopFindAll(params) {
    return contentService.bannerTopFindAll(params);
  },
  bannerTopFindone(id) {
    return contentService.bannerTopFindone(id);
  },
  bannerTopAdd(params) {
    return contentService.bannerTopAdd(params);
  },
  bannerTopModify(id, params) {
    return contentService.bannerTopModify(id, params);
  },
  bannerTopRemove(id) {
    return contentService.bannerTopRemove(id);
  },
  // event
  eventFindAll(params) {
    return contentService.eventFindAll(params);
  },
  eventFindone(id) {
    return contentService.eventFindone(id);
  },
  eventAdd(params) {
    return contentService.eventAdd(params);
  },
  eventModify(id, params) {
    return contentService.eventModify(id, params);
  },
  eventRemove(id) {
    return contentService.eventRemove(id);
  },
  // festival
  festivalFindAll(params) {
    return contentService.festivalFindAll(params);
  },
  festivalFindone(id) {
    return contentService.festivalFindone(id);
  },
  festivalAdd(params) {
    return contentService.festivalAdd(params);
  },
  festivalModify(id, params) {
    return contentService.festivalModify(id, params);
  },
  festivalRemove(id) {
    return contentService.festivalRemove(id);
  },

  // popup
  popupFindAll(params) {
    return contentService.popupFindAll(params);
  },
  popupFindone(id) {
    return contentService.popupFindone(id);
  },
  popupAdd(params) {
    return contentService.popupAdd(params);
  },
  popupModify(id, params) {
    return contentService.popupModify(id, params);
  },
  popupRemove(id) {
    return contentService.popupRemove(id);
  },
  // USERService
  userFindone(id) {
    return userService.userFindone(id);
  },
  // generalUser
  generalUserFindAll(params) {
    return userService.generalFindAll(params);
  },
  generalUserFindone(id) {
    return userService.generalFindone(id);
  },
  generalUserAdd(params) {
    return userService.generalAdd(params);
  },
  generalUserModify(id, params) {
    return userService.generalModify(id, params);
  },
  generalUserRemove(id) {
    return userService.generalRemove(id);
  },
  generalUserRegisterDaily(params) {
    return userService.userRegisterDaily(params);
  },
  registerGenderDaily(params) {
    return userService.registerGenderDaily(params);
  },
  registerCountryDaily(params) {
    return userService.registerCountryDaily(params);
  },
  registerLangDaily(params) {
    return userService.registerLangDaily(params);
  },
  registerAgeDaily(params) {
    return userService.registerAgeDaily(params);
  },

  // clientUser
  clientRegisterDaily(params) {
    return userService.clientRegisterDaily(params);
  },
  clientUserFindAll(params) {
    return userService.clientFindAll(params);
  },
  clientUserFindone(id) {
    return userService.clientFindone(id);
  },
  clientUserAdd(params) {
    return userService.clientAdd(params);
  },
  clientUserModify(id, params) {
    return userService.clientModify(id, params);
  },
  clientUserRemove(id) {
    return userService.clientRemove(id);
  },
  // admin
  adminFindAll(params) {
    return userService.adminFindAll(params);
  },
  adminFindone(id) {
    return userService.adminFindone(id);
  },
  adminAdd(params) {
    return userService.adminAdd(params);
  },
  adminModify(id, params) {
    return userService.adminModify(id, params);
  },
  adminRemove(id) {
    return userService.adminRemove(id);
  },
  // COMMUNITYService
  // postGeneral
  postGeneralFindAll(params) {
    return communityService.postGeneralFindAll(params);
  },
  postFindone(id) {
    return communityService.postFindone(id);
  },
  postFindoneRemove(id) {
    return communityService.postFindoneRemove(id);
  },
  postGeneralAdd(params) {
    return communityService.postGeneralAdd(params);
  },
  postGeneralModify(id, params) {
    return communityService.postGeneralModify(id, params);
  },
  postGeneralRemove(id) {
    return communityService.postGeneralRemove(id);
  },
  // postStamplog
  postStamplogFindAll(params) {
    return communityService.postStamplogFindAll(params);
  },
  postStamplogFindone(id) {
    return communityService.postStamplogFindone(id);
  },
  postStamplogAdd(params) {
    return communityService.postStamplogAdd(params);
  },
  postStamplogModify(id, params) {
    return communityService.postStamplogModify(id, params);
  },
  postStamplogRemove(id) {
    return communityService.postStamplogRemove(id);
  },
  // comment
  commentFindAll(params) {
    return communityService.commentFindAll(params);
  },
  commentFindone(id) {
    return communityService.commentFindone(id);
  },
  commentAdd(params) {
    return communityService.commentAdd(params);
  },
  commentModify(id, params) {
    return communityService.commentModify(id, params);
  },
  commentRemove(id) {
    return communityService.commentRemove(id);
  },
  commentPostFindone(id) {
    return communityService.commentPostFindone(id);
  },
  // like
  likeFindAll(params) {
    return communityService.likeFindAll(params);
  },
  likeFindone(id) {
    return communityService.likeFindone(id);
  },
  likeAdd(params) {
    return communityService.likeAdd(params);
  },
  likeModify(id, params) {
    return communityService.likeModify(id, params);
  },
  likeRemove(id) {
    return communityService.likeRemove(id);
  },
  likePostId(params) {
    return communityService.likePostId(params);
  },
  // share
  shareFindAll(params) {
    return communityService.shareFindAll(params);
  },
  shareFindone(id) {
    return communityService.shareFindone(id);
  },
  shareAdd(params) {
    return communityService.shareAdd(params);
  },
  shareModify(id, params) {
    return communityService.shareModify(id, params);
  },
  shareRemove(id) {
    return communityService.shareRemove(id);
  },
  // report
  reportFindAll(params) {
    return reportService.reportFindAll(params);
  },
  reportFindone(id) {
    return reportService.reportFindone(id);
  },
  reportModify(id, params) {
    return reportService.reportModify(id, params);
  },
  reportRemove(id) {
    return reportService.reportRemove(id);
  },
  // inquiry
  inquiryFindAll(params) {
    return inquiryService.inquiryFindAll(params);
  },
  inquiryFindone(id) {
    return inquiryService.inquiryFindone(id);
  },
  inquiryModify(id, params) {
    return inquiryService.inquiryModify(id, params);
  },
  inquiryRemove(id) {
    return inquiryService.inquiryRemove(id);
  },
  // advertisement
  advertisementFindAll(params) {
    return advertisementService.advertisementFindAll(params);
  },
  advertisementFindone(id) {
    return advertisementService.advertisementFindone(id);
  },
  advertisementAdd(params) {
    return advertisementService.advertisementAdd(params);
  },
  advertisementModify(id, params) {
    return advertisementService.advertisementModify(id, params);
  },
  advertisementRemove(id) {
    return advertisementService.advertisementRemove(id);
  },
  // orderService
  // product
  productFindAll(params) {
    return orderService.productFindAll(params);
  },
  productFindone(id) {
    return orderService.productFindone(id);
  },
  productAdd(params) {
    return orderService.productAdd(params);
  },
  productModify(id, params) {
    return orderService.productModify(id, params);
  },
  productRemove(id) {
    return orderService.productRemove(id);
  },
  // order
  orderFindAll(params) {
    return orderService.orderFindAll(params);
  },
  orderFindone(id) {
    return orderService.orderFindone(id);
  },
  orderAdd(params) {
    return orderService.orderAdd(params);
  },
  orderModify(id, params) {
    return orderService.orderModify(id, params);
  },
  orderRemove(id) {
    return orderService.orderRemove(id);
  },
  // reference
  referenceFindAll(params) {
    return orderService.referenceFindAll(params);
  },
  referenceFindone(id) {
    return orderService.referenceFindone(id);
  },
  referenceAdd(params) {
    return orderService.referenceAdd(params);
  },
  referenceModify(id, params) {
    return orderService.referenceModify(id, params);
  },
  referenceRemove(id) {
    return orderService.referenceRemove(id);
  },

  // Statistics Stamp
  generalAquisitionDaily(params) {
    return stampService.generalAquisitionDaily(params);
  },
  clientAquisitionDaily(params) {
    return stampService.clientAquisitionDaily(params);
  },
  badgeAquisitionDaily(params) {
    return stampService.badgeAquisitionDaily(params);
  },
  generalReviewDaily(params) {
    return stampService.generalReviewDaily(params);
  },
  clientReviewDaily(params) {
    return stampService.clientReviewDaily(params);
  },
  badgeReviewDaily(params) {
    return stampService.badgeReviewDaily(params);
  },
  acquisitionGenderDaily(params) {
    return stampService.acquisitionGenderDaily(params);
  },
  acquisitionCountryDaily(params) {
    return stampService.acquisitionCountryDaily(params);
  },
  acquisitionLangDaily(params) {
    return stampService.acquisitionLangDaily(params);
  },
  acquisitionAgeDaily(params) {
    return stampService.acquisitionAgeDaily(params);
  },
  acquisitionCategoryDaily(params) {
    return stampService.acquisitionCategoryDaily(params);
  },
  stampSearchDaily(params) {
    return stampService.stampSearchDaily(params);
  },
  reviewRateDaily(params) {
    return stampService.reviewRateDaily(params);
  },
  reviewWriteRateDaily(params) {
    return stampService.reviewWriteRateDaily(params);
  },

  // Statistics mission
  generalCompleteDaily(params) {
    return missionService.generalCompleteDaily(params);
  },
  rewardCompleteDaily(params) {
    return missionService.rewardCompleteDaily(params);
  },
  missionReviewDaily(params) {
    return missionService.missionReviewDaily(params);
  },
  missionRewardReviewDaily(params) {
    return missionService.missionRewardReviewDaily(params);
  },
  missionGenderDaily(params) {
    return missionService.missionGenderDaily(params);
  },
  missionCountryDaily(params) {
    return missionService.missionCountryDaily(params);
  },
  missionLangDaily(params) {
    return missionService.missionLangDaily(params);
  },
  missionAgeDaily(params) {
    return missionService.missionAgeDaily(params);
  },
  missionCategoryDaily(params) {
    return missionService.missionCategoryDaily(params);
  },
  missionSearchDaily(params) {
    return missionService.missionSearchDaily(params);
  },
  missionReviewRateDaily(params) {
    return missionService.missionReviewRateDaily(params);
  },
  missionReviewWriteRateDaily(params) {
    return missionService.missionReviewWriteRateDaily(params);
  },

  // Statistics community
  postRegisterDaily(params) {
    return communityService.postRegisterDaily(params);
  },
  commentRegisterDaily(params) {
    return communityService.commentRegisterDaily(params);
  },
  likeRegisterDaily(params) {
    return communityService.likeRegisterDaily(params);
  },
  reportPostDaily(params) {
    return communityService.reportPostDaily(params);
  },
  reportCommentDaily(params) {
    return communityService.reportCommentDaily(params);
  },
  postGenderDaily(params) {
    return communityService.postGenderDaily(params);
  },
  postCountryDaily(params) {
    return communityService.postCountryDaily(params);
  },
  postLangDaily(params) {
    return communityService.postLangDaily(params);
  },
  postAgeDaily(params) {
    return communityService.postAgeDaily(params);
  },
  commentGenderDaily(params) {
    return communityService.commentGenderDaily(params);
  },
  commentCountryDaily(params) {
    return communityService.commentCountryDaily(params);
  },
  commentLangDaily(params) {
    return communityService.commentLangDaily(params);
  },
  commentAgeDaily(params) {
    return communityService.commentAgeDaily(params);
  },
  likeGenderDaily(params) {
    return communityService.likeGenderDaily(params);
  },
  likeCountryDaily(params) {
    return communityService.likeCountryDaily(params);
  },
  likeLangDaily(params) {
    return communityService.likeLangDaily(params);
  },
  likeAgeDaily(params) {
    return communityService.likeAgeDaily(params);
  },
  reportGenderDaily(params) {
    return communityService.reportGenderDaily(params);
  },
  reportCountryDaily(params) {
    return communityService.reportCountryDaily(params);
  },
  reportLangDaily(params) {
    return communityService.reportLangDaily(params);
  },
  reportAgeDaily(params) {
    return communityService.reportAgeDaily(params);
  },
};
