import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// category
const categoryFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/group/category`, {
    params: params,
    headers: authHeader(),
  });
};
const oldCategoryFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/old/group/category`, {
    params: params,
    headers: authHeader(),
  });
};

// districts
const districtsFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/group/districts`, {
    params: params,
    headers: authHeader(),
  });
};
const oldDistrictsFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/old/group/districts`, {
    params: params,
    headers: authHeader(),
  });
};

// group
const groupAdd = (params) => {
  return http.post(SERVICE_API_URL + `/group`, params, {
    headers: authHeader(),
  });
};
const groupFindone = (id) => {
  return http.get(SERVICE_API_URL + `/group/${id}`, {
    headers: authHeader(),
  });
};
const groupModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/group/${id}`, params, {
    headers: authHeader(),
  });
};
const groupRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/group/${id}`, {
    headers: authHeader(),
  });
};
// groupOld
const oldGroupAdd = (params) => {
  return http.post(SERVICE_API_URL + `/old/group`, params, {
    headers: authHeader(),
  });
};
const oldGroupFindone = (id) => {
  return http.get(SERVICE_API_URL + `/old/group/${id}`, {
    headers: authHeader(),
  });
};
const oldGroupModify = (id, params) => {
  return http.put(SERVICE_API_URL + `/old/group/${id}`, params, {
    headers: authHeader(),
  });
};
const oldGroupRemove = (id) => {
  return http.delete(SERVICE_API_URL + `/old/group/${id}`, {
    headers: authHeader(),
  });
};

export default {
  categoryFindAll,
  districtsFindAll,
  oldCategoryFindAll,
  oldDistrictsFindAll,
  groupFindone,
  groupAdd,
  groupRemove,
  groupModify,
  oldGroupFindone,
  oldGroupAdd,
  oldGroupRemove,
  oldGroupModify,
};
